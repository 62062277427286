import React from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import moment from 'moment';
import Accordion from "../../../../lib/project-review-card/components/accordion";

import { FormikInput, BasicTextArea, FormikDatePicker } from '../../../components';
import ProjectReviewCard from '../../../../lib/project-review-card/ProjectReviewCard';

const ProjectTab = ({ calendarEvent = {}, project, fromReviewType, isDisabled, projectReviewsAttributes, setUploadImage, isOpenReviewCard }) => {
  const { errors, touched, setFieldValue } = useFormikContext();
  const { id: projectId = '', projectDate = '', template = {} } = project;
  const { id: calendarEventId = '' } = calendarEvent;
  const { id: templateId = '', templateWorksAttributes = [] } = template;

  const findProjectDate = projectDate || moment(Date.now()).format('DD/MM/yyyy HH:mm');

  const findReviewGroups = reviewType => {
    const filterReviewType = projectReviewsAttributes.filter(pr => pr.reviewType === reviewType);
    const allReviewGroups = filterReviewType.map(pr => pr.reviewGroupName);
    return allReviewGroups.filter((value, index, array) => array.indexOf(value) === index);
  };

  return (
    <>
      <Row>
        <Col md={12} className="my-2">
          {templateId && (
            <Alert variant="secondary">
              Esta OT fue generada a partir de Template #{templateId} y tiene {templateWorksAttributes.length} trabajos
              asociados.
            </Alert>
          )}
        </Col>
        <Col md={12}>
          {projectId ? (
            <h1 className="text-uppercase font-weight-bold" style={{ fontSize: '1.7rem' }}>
              OT #{projectId}
            </h1>
          ) : (
            calendarEventId && <h4 className="text-uppercase">Cita #{calendarEventId}</h4>
          )}
        </Col>
        <Col md={12} className="mb-4">
          <h3
            style={{
              color: '#a19f9f'
            }}
          >
            {findProjectDate}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Accordion
            title={() => {
              return (
                <div>
                  <strong style={{ fontSize: '1.1rem' }}>Información general</strong>
                  <span className="ml-2 text-danger">*</span>
                </div>
              );
            }}
            content={() => (
              <Row>
                {!['mechanic', 'pre_inspection', 'output'].includes(fromReviewType) && (
                  <>
                    <Col md={6}>
                      <Field name="project[promiseParseDate]">
                        {({ field }) => (
                          <FormikDatePicker
                            {...field}
                            abbr
                            label="Fecha estimada de entrega"
                            disabled={isDisabled}
                            dateFormat="dd/MM/yyyy"
                            onChange={date => {
                              setFieldValue(field.name, moment(date).format('DD/MM/YYYY'));
                            }}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field name="project[promiseTime]">
                        {({ field }) => (
                          <FormikInput
                            {...field}
                            abbr
                            type="time"
                            label="Hora de entrega estimada"
                            disabled={isDisabled}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col md={12}>
                      <Field name="project[mileage]">
                        {({ field }) => (
                          <FormikInput
                            {...field}
                            abbr
                            inputType="number"
                            label="Kilometraje"
                            disabled={isDisabled}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                  </>
                )}
                <Col md={12}>
                  <Field name="project[notes]">
                    {({ field }) => (
                      <BasicTextArea
                        {...field}
                        row="1" // fix rows is not working
                        label="Motivo / Notas"
                        disabled={isDisabled}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            )}
            defaultOpen
          />
        </Col>
      </Row>
      <hr />

      {projectReviewsAttributes &&
        projectReviewsAttributes.length > 0 &&
        findReviewGroups(fromReviewType).map((reviewGroupName, indexReviewGroup) => (
          <div key={`review-group-${indexReviewGroup.toString()}`} className="mt-3">
            <h3 className="mb-1 text-capitalize" style={{ fontSize: '1rem', fontWeight: 500, color: '#1387c4' }}>
              {reviewGroupName.toLowerCase()}
            </h3>
            {projectReviewsAttributes.map(
              (projectReview, indexProjectReview) =>
                reviewGroupName === projectReview.reviewGroupName &&
                projectReview.reviewType === fromReviewType && (
                  <ProjectReviewCard
                    key={`project-review-${indexProjectReview.toString()}`}
                    fromReviewType={fromReviewType}
                    projectReview={projectReview}
                    project={project}
                    index={indexProjectReview}
                    isDisabled={isDisabled}
                    isOpenReviewCard={isOpenReviewCard}
                    setUploadImage={setUploadImage}
                  />
                )
            )}
            <hr />
          </div>
        ))}
    </>
  );
};

export default ProjectTab;
