import React, { useState, useEffect } from 'react';
import { Row, Col, FormControl, Form, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import PropTypes from 'prop-types';
import useSavedFilter from "../../../hooks/useSavedFilter";
import InputDate from "../Input/InputDate";

const Debounce = AwesomeDebouncePromise(value => value, 500);

const ComponentDataTable = (props) => {
  const {getFilter, setFilter} = useSavedFilter(props.filterId);
  const {initialQuery, startDate, endDate} = props;
  const [state, setState] = useState({
    date_from: getFilter('date_from') || startDate || '',
    date_to: getFilter('date_to') || endDate || '',
    query: getFilter('query') || initialQuery || '',
    page: 0,
    RowsPage: props.RowsPage,
    column: '',
    direction: 'desc'
  });

  const setRequest = (params) => {
    const {handleSortCase, resourceRequest, moreData} = props;
    const {RowsPage, page, query, direction, column, date_from, date_to} = state;
    let sortColumn = {sort_column: column};
    if (handleSortCase) {
      sortColumn = handleSortCase(column);
    }
    resourceRequest({
      query,
      date_from,
      date_to,
      display_start: page * RowsPage,
      display_length: RowsPage,
      sort_direction: direction,
      ...sortColumn,
      ...moreData,
      ...params
    });
  };

  useEffect(() => {
    setRequest();
  }, [props.moreData]);

  useEffect(() => {
    setRequest({
      display_start: 0,
      sort_direction: state.direction
    });
  }, [state.direction, state.column]);

  const onChangePage = (page) => {
    const {RowsPage} = state;
    setState(prevState => ({
      ...prevState,
      page: page - 1
    }));

    setRequest({
      display_start: (page - 1) * RowsPage
    });
  };

  const onChangeRowsPage = (RowsPage) => {
    setState(prevState => ({
      ...prevState,
      RowsPage
    }));

    setRequest({
      display_start: 0,
      display_length: RowsPage
    });
  };

  const handleInputChange = async (name, value) => {
    setState(prevState => ({
      ...prevState,
      [name]: value,
      page: 0
    }));
    await Debounce(value);
    setFilter(name, value);
    const params = {}
    params[name] = value;
    setRequest(params);
  };

  const handleSort = (column, direction) => {
    setState(prevState => ({
      ...prevState,
      direction,
      column: column.selector,
      page: 0
    }));
  };

  const {
    columns,
    onRequest,
    data,
    totalRows,
    RowsPage,
    expandable,
    withDate,
    conditionalRowStyles,
    firstCustomMediumSearch,
    secondCustomMediumSearch,
    thirdCustomMediumSearch,
    fourthCustomMediumSearch,
    fifthCustomMediumSearch,
    customSearchLong,
    noSearch,
    noSearchField,
    filters
  } = props;
  const {query, date_from, date_to} = state;

  return (
    <div className={noSearch ? 'no-search-data-table' : 'mt-4'} style={{position: 'relative'}}>
      {onRequest && (
        <div className="containerSpinnerLoad" style={{position: 'absolute', height: '100%'}}>
          <Spinner animation="border" variant="primary"/>
        </div>
      )}
      <DataTable
        className="custom-data-table"
        pagination
        conditionalRowStyles={conditionalRowStyles}
        noDataComponent={onRequest ? '' : 'No se encontraron datos.'}
        paginationServer
        expandableRows={expandable}
        paginationTotalRows={totalRows}
        paginationPerPage={RowsPage}
        paginationRowsPerPageOptions={[10, 25, 50]}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPage}
        paginationComponentOptions={{
          rowsPerPageText: 'Filas por pagina:',
          rangeSeparatorText: 'de'
        }}
        sortServer
        onSort={(column, direction) => handleSort(column, direction)}
        sortFunction={a => a}
        noHeader
        subHeader
        disabled={onRequest}
        columns={columns}
        data={data}
        subHeaderComponent={
          <Row>
            {withDate && (
              <>
                <Col xs={4}>
                  <Form.Group>
                    <InputDate
                      placeholderText="Desde"
                      selected={date_from}
                      onChange={date => handleInputChange('date_from', date)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={4}>
                  <Form.Group>
                    <InputDate
                      placeholderText="Hasta"
                      selected={date_to}
                      onChange={date => handleInputChange('date_to', date)}
                    />
                  </Form.Group>
                </Col>
              </>
            )}
            {!noSearchField && (
              <Col md={withDate ? 4 : 12}>
                <Form.Group>
                  <FormControl
                    placeholder="Buscar"
                    name="query"
                    value={query}
                    onChange={e => handleInputChange('query', e.target.value)}
                  />
                </Form.Group>
              </Col>
            )}

            <div className="datatable-custom-filters-container">
              {firstCustomMediumSearch && firstCustomMediumSearch}
              {secondCustomMediumSearch && secondCustomMediumSearch}
              {thirdCustomMediumSearch && thirdCustomMediumSearch}
              {fourthCustomMediumSearch && fourthCustomMediumSearch}
              {fifthCustomMediumSearch && fifthCustomMediumSearch}
            </div>
            {filters}
            {customSearchLong && <Col md={12}>{customSearchLong}</Col>}
          </Row>
        }
      />
    </div>
  );
};

ComponentDataTable.propTypes = {
  expandable: PropTypes.bool,
  moreData: PropTypes.bool,
  onRequest: PropTypes.bool,
  resourceRequest: PropTypes.func,
  RowsPage: PropTypes.number,
  totalRows: PropTypes.number,
  withDate: PropTypes.bool
};

ComponentDataTable.defaultProps = {
  expandable: false,
  moreData: '',
  onRequest: false,
  resourceRequest: () => null,
  RowsPage: 10,
  totalRows: 0,
  withDate: false
};

export default ComponentDataTable;