import ArCountryFactory from "./ArCountryFactory";
import ClCountryFactory from "./ClCountryFactory";
import CrCountryFactory from "./CrCountryFactory";

export const uuid = () => Math.floor(Math.random() * Date.now()).toString();

export function debounce(func, delay) {
  let debounceTimer;
  return function(...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
}

export const getCountryFactory = (country) => {
  if (country === 'ar') {
    return new ArCountryFactory()
  }
  if (country === 'cr') {
    return new CrCountryFactory()
  }
  return new ClCountryFactory()
}

export const formatAmount = (amount) =>
  Number(amount).toLocaleString('es-CL', { maximumFractionDigits: 0 });

