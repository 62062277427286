import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Field, getIn, useFormikContext } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { ExternalLink, Info, Trash } from 'react-feather';
import ReactTooltip from 'react-tooltip';

import { sendAlert } from '../../../actions/utils';
import {
  CategoryProductCategorySelect,
  FormikInput,
  FormikNumberFormat,
  ProductCustomSelect,
  SimpleCenteredModal
} from '../../../components';
import PurchaseManagementDetailTable from '../../../components/PurchaseManagement';
import { indexProductsRequest } from '../../../requests/products';
import { toInteger } from '../../../services/utils';

const PurchaseManagementDetailForm = ({ index, purchaseManagementDetail }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const { purchaseManagementDetailsAttributes } = values.purchaseManagement;
  const inputName = `purchaseManagement[purchaseManagementDetailsAttributes][${index}]`;
  const {
    productId,
    productName,
    productNameCustom,
    projectDetail,
    quantity,
    totalPrice,
    unitPrice
  } = purchaseManagementDetail;
  const { freeQuotedCost } = projectDetail;
  const [currentDetailQuantity, setCurrentDetailQuantity] = useState(quantity);
  const [currentDetailUnitPrice, setCurrentDetailUnitPrice] = useState(unitPrice);
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [products, setProducts] = useState([]);
  const [productsDisabled, setProductsDisabled] = useState(true);
  const dispatch = useDispatch();

  const handleRemoveBtn = () => {
    const arrayValuesLength = purchaseManagementDetailsAttributes.length;
    const deleteValue = purchaseManagementDetail;
    const newValues = [
      ...purchaseManagementDetailsAttributes.slice(0, index),
      ...purchaseManagementDetailsAttributes.slice(index + 1, arrayValuesLength)
    ];
    if (deleteValue && deleteValue.id) {
      deleteValue._destroy = 'true';
      newValues.push(deleteValue);
    }
    setFieldValue('purchaseManagement[purchaseManagementDetailsAttributes]', newValues);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const setFirstProductsOption = array => {
    array.unshift({ label: 'Ítem libre', cost: '', value: 0, custom: true });
    return camelCaseRecursive(array);
  };

  const handleSuccessRequest = response => {
    const { data } = response.data;
    setProducts(setFirstProductsOption(data));
    setProductsDisabled(false);
  };

  const fetchInitialProducts = params => {
    const categoryId = purchaseManagementDetail.productCategoryId || params?.productCategoryId;
    if (!categoryId) return;
    indexProductsRequest({
      dispatch,
      params: {
        ...params,
        active: true,
        for_selector: true,
        product_category_id: categoryId,
        sort_column: 'code',
        sort_direction: 'asc',
        display_length: 100
      },
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleChangeQuantity = e => {
    setCurrentDetailQuantity(e.target.value);
    setFieldValue(e.target.name, e.target.value);
  };

  const handleChangeUnitPrice = e => {
    const formattedValue = e.target.value.replace(/\./g, '');
    setCurrentDetailUnitPrice(formattedValue);
    setFieldValue(e.target.name, formattedValue);
    setFieldValue(`${inputName}[unitPrice]`, formattedValue);
    setFieldValue(`${inputName}[parsedUnitPrice]`, formattedValue);
  };

  const cleanSelectedProduct = () => {
    setFieldValue(`${inputName}[productId]`, '');
    setFieldValue(`${inputName}[productName]`, '');
  };

  const handleSubcategoryChange = value => {
    cleanSelectedProduct();
    if (value) fetchInitialProducts({ productCategoryId: value });
    else setProductsDisabled(true);
  };

  const handleParentCategoryChange = () => {
    cleanSelectedProduct();
    setProductsDisabled(true);
    setProducts([]);
  };

  const recalculatePrice = () => {
    const currentParsedUnitPrice = currentDetailUnitPrice || 0;
    const parsedQuantity = parseFloat(currentDetailQuantity, 10) || 0;
    const calculatedPrice = parsedQuantity * currentParsedUnitPrice;
    setFieldValue(`${inputName}[price]`, calculatedPrice);
  };

  const recalculateTotalPrice = () => {
    const validQuantity = quantity || 0;
    const validUnitPrice = unitPrice || 0;
    const calculatedTotalPrice = validUnitPrice * validQuantity;
    setFieldValue(`${inputName}[totalPrice]`, calculatedTotalPrice);
  };

  const cleanPrice = () => {
    setFieldValue(`${inputName}[parsedUnitPrice]`, '');
    setFieldValue(`${inputName}[price]`, '');
  };

  const handleProductPrice = () => {
    if (productId || productNameCustom) recalculatePrice();
    else cleanPrice();
  };

  const initialSalePrice = () => {
    const newSalePrice = toInteger(projectDetail?.price) || 0;
    setFieldValue(`${inputName}[salePrice]`, newSalePrice);
  };

  const initialUnitPrice = () => {
    const newUnitPrice = productNameCustom ? freeQuotedCost : unitPrice || 0;
    setFieldValue(`${inputName}[unitPrice]`, newUnitPrice);
  };

  useEffect(fetchInitialProducts, []);
  useEffect(initialSalePrice, []);
  useEffect(initialUnitPrice, []);
  useEffect(handleProductPrice, [productId, productNameCustom]);
  useEffect(recalculatePrice, [currentDetailUnitPrice, currentDetailQuantity]);
  useEffect(recalculateTotalPrice, [quantity, unitPrice]);

  const tooltipContent = () =>
    projectDetail?.notes && (
      <>
        <Info width="15px" height="15px" className="mx-1" data-for={`notes-${projectDetail?.id}`} data-tip />
        <ReactTooltip id={`notes-${projectDetail?.id}`} place="top">
          {projectDetail?.notes}
        </ReactTooltip>
      </>
    );

  const purchaseInfo = () => {
    const pmdContent = purchaseManagementDetailsAttributes.filter(pmd => pmd.projectDetailId === projectDetail.id);

    setModalShow(true);
    setModalTitle(productName || productNameCustom);
    setModalBody(<PurchaseManagementDetailTable purchaseManagementDetails={pmdContent} />);
  };

  return (
    <Row className="item-background mt-4 mx-0 pb-1 px-1 d-flex align-items-baseline">
      <Col md={1}>
        <Field>
          {({ field }) => (
            <FormikInput
              {...field}
              disabled
              label="OT"
              value={projectDetail?.projectId}
              additionalIconLabel={
                <>
                  {tooltipContent()}
                  <Button variant="link" className="custom-external-link" onClick={purchaseInfo}>
                    <ExternalLink width="15px" height="15px" />
                  </Button>
                </>
              }
            />
          )}
        </Field>
        <SimpleCenteredModal
          size="xl"
          title={modalTitle}
          body={modalBody}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </Col>
      <Col>
        <Row>
          <Col md={8}>
            <Row>
              <CategoryProductCategorySelect
                categoryAbbr
                modelKey={inputName}
                currentModel={purchaseManagementDetail || {}}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                onParentCategoryChange={handleParentCategoryChange}
                onSubCategoryChange={handleSubcategoryChange}
              />
            </Row>
          </Col>
          <Col md={4}>
            <ProductCustomSelect
              showCost
              products={products}
              modelKey={inputName}
              modelDetail={purchaseManagementDetail}
              setFirstProductsOption={setFirstProductsOption}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              disabled={productsDisabled}
              setCurrentDetailQuantity={setCurrentDetailQuantity}
              setCurrentDetailUnitPrice={setCurrentDetailUnitPrice}
            />
          </Col>
        </Row>
      </Col>
      <Col md={1}>
        <Field name={`${inputName}[quantity]`}>
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              inputType="number"
              label="Cantidad"
              onChange={handleChangeQuantity}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={4}>
        <Row>
          <Col md={4}>
            <Field name={`${inputName}[parsedUnitPrice]`}>
              {({ field }) => (
                <FormikNumberFormat
                  {...field}
                  leftAddon="$"
                  label="C/C Referencial"
                  value={unitPrice}
                  onChange={handleChangeUnitPrice}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={4}>
            <Field name={`${inputName}[parsedTotalPrice]`}>
              {({ field }) => (
                <FormikNumberFormat
                  {...field}
                  disabled
                  leftAddon="$"
                  label="Costo Total"
                  value={totalPrice}
                  onValueChange={formatType => setFieldValue(`${inputName}[totalPrice]`, formatType?.value)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={4}>
            <Field name={`${inputName}[salePrice]`}>
              {({ field }) => (
                <FormikNumberFormat
                  {...field}
                  disabled
                  leftAddon="$"
                  label="Valor Cliente"
                  value={toInteger(projectDetail?.price) || 0}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Col>
      <div className="trash-container custom-trash">
        <Trash className="clickable delete-item" onClick={handleRemoveBtn} />
      </div>
    </Row>
  );
};

export default PurchaseManagementDetailForm;
