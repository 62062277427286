import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ComponentDataTable from '../../components/Utils/DataTable';
import { sendAlert } from '../../actions/utils';
import {debounceIndexAssignedTaskRequest} from "../../requests/assignedTasks";

const AssignedTaskDataTable = props => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [bulkUploads, setNpsAnswers] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const { columns, moreData, setMoreData } = props;
  const { user: currentUser } = useSelector(state => state.auth);

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleSuccessRequest = response => {
    const data = response.data.data;
    const { amount: responseAmount } = response.data.meta;
    setNpsAnswers(data);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    debounceIndexAssignedTaskRequest({
      dispatch,
      params: {
        ...params,
        q: {
          assigned_user_id_eq: currentUser.id,
          filtered: params?.query || ''
        }
      },
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  useEffect(handleIndexRequest, [currentUser]);

  return (
    <ComponentDataTable
      onRequest={onRequest}
      columns={columns()}
      data={bulkUploads}
      totalRows={amount}
      moreData={moreData}
      resourceRequest={response => {
        if (!onRequest) handleIndexRequest(response);
      }}
    />
  );
};

export default AssignedTaskDataTable;
