/* eslint-disable camelcase */
import React from 'react';
import { Button } from 'react-bootstrap';
import memoize from 'memoize-one';
import { BasicActionBtns } from '../../components';
import ProjectPromiseDateEdit from './ProjectPromiseDateEdit';
import { ProjectMechanisStateColors } from '../../constants';
import useIsMechanic from "../../hooks/useIsMechanic";

const columns = memoize((clickHandler, fromMechanicList, ability, handleRedirect, setMoreData, isQuote=false) => {
  const isMechanic = useIsMechanic();

  const tempArray = [
    { name: 'FOLIO', selector: 'id', sortable: true, grow: '1', width: '80px' },
    {
      name: 'CÓDIGO',
      selector: 'plate',
      cell: item => {
        return item.vehicle?.plate ? (
          <Button className="btn-link-no-hover-effects" onClick={() => handleRedirect(null, item.vehicle?.plate)}>
            {item.vehicle?.plate}
          </Button>
        ) : (
          <span>No Aplica</span>
        );
      },
      sortable: true,
      grow: '2'
    },
    {
      name: 'CLIENTE',
      selector: 'client',
      cell: item => (
        <Button className="btn-link-no-hover-effects" onClick={() => handleRedirect(item.client?.id)}>
          {item.client?.full_name}
        </Button>
      ),
      sortable: true,
      grow: '4'
    },
    {
      name: 'MARCA',
      selector: 'brand',
      cell: item => item.vehicle?.vehicle_brand_name,
      sortable: true,
      grow: '2'
    },
    {
      name: 'MODELO',
      selector: 'model',
      cell: item => item.vehicle?.vehicle_model_name,
      sortable: true,
      grow: '3'
    },
    { name: 'KILOMETRAJE', selector: 'parsed_mileage', sortable: true, grow: '3' }
  ];
  if (!isQuote && !isMechanic) {
    tempArray.push({
      name:!fromMechanicList ? 'ESTADO COBRO': 'TOTAL',
      selector: !fromMechanicList ? 'translated_invoice_state' : 'parsed_total_price',
      sortable: true,
      grow: '3'
    });
  }
  tempArray.push(
    {
      name: 'ESTADO OT',
      selector: 'translated_ot_state',
      sortable: true,
      grow: '2',
      cell: item => (
        <span
          className="badge p-2"
          style={{
            backgroundColor: ProjectMechanisStateColors[item?.ot_state]
          }}
        >
          {item?.translated_ot_state?.toUpperCase()}
        </span>
      )
    }
  );
  if (isQuote) {
    tempArray.push({
      name: 'ENVIO DE PRESUPUESTO',
      selector: 'formated_last_quote_sent',
      cell: item => item.formated_last_quote_sent,
      sortable: false,
      grow: '3'
    });
  }
  tempArray.push(
    {
      name: 'FECHA CREACIÓN',
      selector: 'project_date',
      cell: item => item.formated_project_date_no_time,
      sortable: true,
      grow: '2'
    }
  );
  if (!fromMechanicList) {
    tempArray.push({
      name: 'FECHA DE ENTREGA',
      selector: 'formated_delivered_date_no_time',
      cell: item => item?.formated_delivered_date_no_time,
      sortable: true,
      grow: '2'
    });
  } else {
    tempArray.push({
      name: 'FECHA ESTIMADA DE ENTREGA',
      selector: 'formated_promise_date_no_time',
      cell: item => (
        <ProjectPromiseDateEdit project={item} setMoreData={setMoreData}>
          {item?.formated_promise_date_no_time}
        </ProjectPromiseDateEdit>
      ),
      sortable: true,
      grow: '3'
    });
  }
  if (!fromMechanicList) {
    tempArray.push({
      name: 'SUCURSAL',
      selector: 'branch_office',
      cell: item => item.branch_office?.name,
      sortable: true,
      grow: '2'
    });
  }
  tempArray.push({
    cell: item =>
      ability.can('manage', 'Project') ? (
        <BasicActionBtns
          item={item}
          clickHandler={clickHandler}
          mechanicReview={fromMechanicList}
          showClick
          editClick
          withDestroy={!fromMechanicList}
        />
      ) : (
        <BasicActionBtns
          item={item}
          clickHandler={clickHandler}
          mechanicReview
          withShow={false}
          withEdit={false}
          withDestroy={false}
        />
      ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '2',
    minWidth: '140px'
  });

  return tempArray;
});

export default columns;
