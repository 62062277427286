import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import ProjectNavbarInfo from '../ProjectNavbarInfo';
import ProjectClient from '../ProjectClient';
import ProjectVehicle from '../ProjectVehicle';
import ProjectProgressBars from '../ProjectProgressBars';
import ProjectImages from '../ProjectImages';
import ProductShow from './ProductShow';
import ProjectPaymentsShow from '../../ProjectPayment/ProjectPaymentsShow';
import ProjectClient2 from '../ProjectClient2';
import { LinkBtn } from '../../../components';
import getImage from '../../../utils/getImage';

const ProjectPublicContent = ({
  project,
  userId,
  currentVersion,
  version,
  setMoreData,
  setShowMechanicChecklistAtStart,
  setShowReceptionChecklistAtStart,
  showMechanicChecklistAtStart,
  showReceptionChecklistAtStart,
  receptionImages,
  mechanicImages,
  preInspectionImages,
  privateProject,
  token,
  preview = false
}) => {
  const { id } = project;

  const images = (imagesInfo = []) => {
    if (imagesInfo.length) {
      return imagesInfo.map(image => ({
        original: image.fileUrl,
        thumbnail: getImage(image.key, 100, 100, 'inside')
      }));
    }
    return [];
  };

  return (
    <Container className="pb-5">
      <Row className="my-4">
        <Col>
          <h2 className="text-uppercase">Orden de Trabajo</h2>
        </Col>
        {!!userId && (
          <Col md={2}>
            <LinkBtn variant="light-darker" block to={`/projects/${id}/versions/${version}`} className="cancel">
              Volver
            </LinkBtn>
          </Col>
        )}
      </Row>
      <Row>
        <Col className="project-section public">
          <ProjectNavbarInfo publicVersion project={project} currentVersion={currentVersion} />
        </Col>
      </Row>
      <Row>
        <Col className="project-section public">
          <ProjectClient version project={project} />
          {project.secondClient && <ProjectClient2 client={project.secondClient} />}
        </Col>
        <Col className="project-section public">
          <ProjectVehicle version project={project} />
        </Col>
      </Row>
      <Row>
        <Col md={3} className="project-section public">
          <ProjectProgressBars
            project={privateProject}
            id={id}
            version={version}
            versionNum={project ? project.versionNumber : ''}
            setMoreData={setMoreData}
            setShowMechanicChecklistAtStart={setShowMechanicChecklistAtStart}
            setShowReceptionChecklistAtStart={setShowReceptionChecklistAtStart}
            showMechanicChecklistAtStart={showMechanicChecklistAtStart}
            showReceptionChecklistAtStart={showReceptionChecklistAtStart}
            publicVersion
          />
        </Col>
        <Col md={3} className="project-section public">
          <Row>
            <Col md={10}>
              <strong>Notas</strong>
            </Col>
            <Col md={12} className="pt-2">
              <pre>{project.notes}</pre>
            </Col>
          </Row>
        </Col>
        <Col md={6} className="project-section public">
          <Row>
            <Col md={12}>
              <strong>Imágenes</strong>
            </Col>
            {project.orderType === 'reception_secure' && (
              <Col className="gallery-small-box">
                <ProjectImages images={images(preInspectionImages)} title="Checklist de Pre Inspección" />
              </Col>
            )}
            <Col className="gallery-small-box">
              <ProjectImages images={images(receptionImages)} title="Checklist recepción" />
            </Col>
            <Col className="gallery-small-box">
              <ProjectImages images={images(mechanicImages)} title="Checklist mecánico" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="project-section public">
          <ProductShow publicVersion project={project} preview={preview} token={token} version={version}/>
        </Col>
      </Row>
      {project.projectPaymentsAttributes?.length > 0 && (
        <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Col md={6} className="project-section mr-2 ml-2 pt-3">
            <Col>
              <ProjectPaymentsShow publicVersion project={project} setMoreData={setMoreData} version={version} />
              <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Col md={5} className="mr-4" />
                <Col>
                  <h3 className="text-danger">Monto Pendiente</h3>
                </Col>
                <Col md={3} className="mr-2">
                  <h3 className="text-danger">{project.parsedProjectRemainingUnpaid}</h3>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
      )}
    </Container>
  );
};
export default ProjectPublicContent;
