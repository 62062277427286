/* eslint-disable camelcase */
import memoize from 'memoize-one';
import {Link} from "react-router-dom";
import React from "react";
import { Eye } from "react-feather";
import { ButtonTooltip } from "../../components";

const columns = memoize(() => [
  { name: 'N° OT',
    selector: row => row.project.id,
    sortable: true,
    grow: '0.5'
  },
  {
    name: 'TRABAJO',
    selector: row => row.project_work?.name || '-',
    sortable: true,
    grow: '0.9'
  },
  {
    name: 'DETALLE TRABAJO',
    selector: 'label',
    sortable: true,
    grow: '0.9'
  },
  {
    name: 'PERSONAL ASIGNADO',
    selector: row => row.assigned_user_name || 'No Asignado',
    sortable: true,
    grow: '1'
  },
  {
    name: 'REALIZADO',
    selector: row => row.done ? 'Si' : 'No',
    sortable: true,
    grow: '1'
  },
  {
    name: 'REALIZADO POR',
    selector: row => row.done_by_name || '-',
    sortable: true,
    grow: '1'
  },
  {
    name: '',
    selector: row => <ButtonTooltip
      to={`/project_mechanic_reviews/${row.project.id}`}
      variant="outline-info"
      className="btn-circle table-actions-btn"
      size="sm"
      text="Revisar OT"
      as={Link}
    >
      <Eye width="15px" height="15px" />
    </ButtonTooltip>,
    sortable: false,
    grow: '0.5'
  }

]);

export default columns;