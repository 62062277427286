import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn } from 'formik';
import camelCaseRecursive from 'camelcase-keys-recursive';

import {
  CategoryProductCategorySelect,
  FormikCheckBox,
  FormikInput,
  FormikNumberFormat,
  ProductCustomSelect
} from '../../components';
import { indexProductsRequest } from '../../requests/products';
import { toInteger } from '../../services/utils';

const WorkDetailInput = ({ index, errors, touched, setFieldValue, values, workDetail }) => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const [products, setProducts] = useState([]);
  const [productsDisabled, setProductsDisabled] = useState(true);
  const inputName = `work[workDetailsAttributes][${index}]`;
  const {
    copyPrice,
    parsedUnitPrice,
    product,
    productCategoryId,
    productId,
    productNameCustom,
    quantity,
    unitPrice
  } = workDetail;

  const [currentDetailUnitPrice, setCurrentDetailUnitPrice] = useState(unitPrice);
  const [currentDetailQuantity, setCurrentDetailQuantity] = useState(quantity);
  const [currentDetailCopyPrice, setCurrentDetailCopyPrice] = useState(copyPrice);

  const setFirstProductsOption = array => {
    array.unshift({ label: 'Ítem libre', salePrice: '', value: 0, custom: true });
    return camelCaseRecursive(array);
  };

  const setPosition = () => {
    setFieldValue(`${inputName}[position]`, index);
  };

  useEffect(setPosition, [index]);

  const fetchInitialProducts = useCallback(
    params => {
      indexProductsRequest({
        dispatch: stableDispatch,
        params: {
          ...params,
          active: true,
          for_selector: true,
          sort_column: 'code',
          sort_direction: 'asc',
          display_length: 100
        },
        successCallback: response => {
          setProducts(setFirstProductsOption(response.data.data));
        }
      });
      setProductsDisabled(false);
    },
    [stableDispatch]
  );

  useEffect(() => {
    if (productCategoryId) fetchInitialProducts({ productCategoryId });
  }, [productCategoryId, fetchInitialProducts]);

  const recalculateTotalPrice = () => {
    const currentParsedUnitPrice = currentDetailUnitPrice || 0;
    const parsedQuantity = parseFloat(currentDetailQuantity, 10) || 0;
    const calculatedPrice = parsedQuantity * currentParsedUnitPrice;
    setFieldValue(`${inputName}[price]`, calculatedPrice);
  };

  const cleanPrice = () => {
    setCurrentDetailUnitPrice('');
    setFieldValue(`${inputName}[parsedUnitPrice]`, '');
    setFieldValue(`${inputName}[price]`, '');
  };

  const handleProductPrice = () => {
    if (productId || productNameCustom) recalculateTotalPrice();
    else cleanPrice();
  };

  useEffect(handleProductPrice, [productId, productNameCustom]);
  useEffect(recalculateTotalPrice, [currentDetailUnitPrice, currentDetailQuantity, currentDetailCopyPrice]);

  const handleChangeQuantity = e => {
    setCurrentDetailQuantity(e.target.value);
    setFieldValue(e.target.name, e.target.value);
  };

  const handleChangeUnitPrice = e => {
    const formattedValue = e.target.value.replace('.', '');
    setCurrentDetailUnitPrice(formattedValue);
    setFieldValue(e.target.name, formattedValue);
    setFieldValue(`${inputName}[unitPrice]`, formattedValue);
    setFieldValue(`${inputName}[parsedUnitPrice]`, formattedValue);
  };

  const handleUnitPrice = newValue => {
    if (!productId) return;
    if (!currentDetailCopyPrice) {
      setCurrentDetailUnitPrice(product?.salePrice);
    } else {
      setCurrentDetailUnitPrice('');
    }
    let vUnitPrice = '';
    if (newValue) vUnitPrice = toInteger(product.salePrice);
    setFieldValue(`${inputName}[parsedUnitPrice]`, vUnitPrice);
    setCurrentDetailCopyPrice(!currentDetailCopyPrice);
  };

  const clearSelectedProduct = () => {
    setFieldValue(`${inputName}[productId]`, '');
    setFieldValue(`${inputName}[productName]`, '');
  };

  const handleParentCategoryChange = () => {
    clearSelectedProduct();
    setProductsDisabled(true);
    setProducts([]);
  };

  const handleSubCategoryChange = value => {
    clearSelectedProduct();
    if (value) {
      fetchInitialProducts({ productCategoryId: value });
    } else {
      setProductsDisabled(true);
    }
  };

  return (
    <Col>
      <Row className={`${!productId ? 'mb-3' : ''}`}>
        <CategoryProductCategorySelect
          categoryAbbr
          modelKey={inputName}
          currentModel={workDetail || {}}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          onParentCategoryChange={handleParentCategoryChange}
          onSubCategoryChange={handleSubCategoryChange}
        />
        <Col md={4}>
          <ProductCustomSelect
            products={products}
            modelKey={inputName}
            modelDetail={workDetail}
            setFirstProductsOption={setFirstProductsOption}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            disabled={productsDisabled}
            setCurrentDetailQuantity={setCurrentDetailQuantity}
            setCurrentDetailUnitPrice={setCurrentDetailUnitPrice}
          />
        </Col>
        <Col md={2}>
          <Field name={`${inputName}[quantity]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="number"
                label="Cantidad"
                onChange={handleChangeQuantity}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3}>
          <Field name={`${inputName}[parsedUnitPrice]`}>
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                leftAddon="$"
                label="Valor Unitario"
                disabled={copyPrice}
                value={copyPrice ? toInteger(product.salePrice) : parsedUnitPrice}
                onChange={handleChangeUnitPrice}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3}>
          <Field name={`${inputName}[price]`}>
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                disabled
                leftAddon="$"
                label="Subtotal neto"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        {productId && (
          <Col md={4} className="pl-0">
            <Field name={`${inputName}[copyPrice]`}>
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  field={field}
                  label="Usar valor unitario del ítem"
                  margin="mb-0"
                  onClick={btn => handleUnitPrice(!btn.target.value)}
                />
              )}
            </Field>
          </Col>
        )}
      </Row>
    </Col>
  );
};

export default WorkDetailInput;
