export const EXTERNAL_API_CONFIG = {
  urlAm: 'https://apiproductos.automarco.cl:3000/api/v1'
};

const API_CONFIG = {
  domain:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_PRODUCTION_URL
      : process.env.REACT_APP_API_STAGING_URL,
  version: 'v1',
  url: version => API_CONFIG.domain + (version ? version : API_CONFIG.version),
  globalTimeout: 15 * 1000,
  googleAnalytics:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_PRODUCTION_TRACKING
      : process.env.REACT_APP_STAGING_TRACKING,
  timeoutMessage: 'Está tardando demasiado, verifica tu conexión a internet e intenta nuevamente'
};

export default API_CONFIG;
