import CountryFactory from "./CountryFactory";

export default class CrCountryFactory extends CountryFactory {
  get formatPhone() {
    return "+506 ####-####";
  }

  handleNationalIdentificationFormat(e, setFieldValue, isForeign) {
    if (isForeign) {
      setFieldValue(e.target.name, e.target.value);
    } else {
      const onlyNumbers = e.target.value.replace(/\D/g, '');
      const numberFormatted = onlyNumbers !== '' ? Number(onlyNumbers).toLocaleString('es-CR') : '';
      setFieldValue(e.target.name, numberFormatted);
    }
  }

  isValidRut(rut) {
    return true;
  }

  getLabelForPersonalIdentification() {
    return "C.I.";
  }

  getLabelForCompanyIdentification() {
    return "RUC";
  }

  getLabelForRegion() {
    return "Provincia";
  }

  getLabelForCommune() {
    return "Distrito";
  }
}