import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import camelCaseRecursive from 'camelcase-keys-recursive';
import moment from 'moment';
import { parse } from 'date-fns';
import * as Yup from 'yup';

import { BasicTextArea, FormikInput } from '../../components';
import { indexVehiclesRequest } from '../../requests/vehicles';
import ClientTab from '../../components/Client/ClientTab';
import VehicleTab from '../../components/Vehicle/VehicleTab';
import basicCompany from '../Company/basicCompany';
import { showCompanyRequest } from '../../requests/companies';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import basicVehicle from '../Vehicle/basicVehicle';

const ProjectDeskSellForm = props => {
  const {
    action,
    errors,
    handleClose,
    onHide,
    project,
    setFieldValue,
    submitVariant,
    touched,
    values,
    isQuote
  } = props;

  const btnMessage = action === 'new' ? 'Crear' : 'Actualizar';
  const [clientDisabled, setClientDisabled] = useState(false);
  const [clientSelected, setClientSelected] = useState('');
  const [currentClient, setCurrentClient] = useState({});
  const [currentCompany, setCompany] = useState(basicCompany);
  const [currentVehicle, setCurrentVehicle] = useState({});
  const [newClient, setNewClient] = useState(false);
  const [newVehicle, setNewVehicle] = useState(false);
  const [vehicleDisabled, setVehicleDisabled] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [vehicleSelected, setVehicleSelected] = useState('');
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const currentValues = getIn(values, 'project');
  const currentClientId = currentValues.clientId;

  const resultFetchData = useCallback(response => {
    const result = response.data.data;
    const tempArray = result.map(element => {
      return {
        ...element,
        label: element.plate,
        value: element.id
      };
    });
    setVehicles(camelCaseRecursive(tempArray));
    return tempArray;
  }, []);

  const fetchInitialVehicles = useCallback(
    params => {
      indexVehiclesRequest(params.clientId, {
        dispatch: stableDispatch,
        params: {
          ...params,
          sort_column: 'plate',
          sort_direction: 'asc',
          display_length: 100
        },
        successCallback: resultFetchData
      });
    },
    [stableDispatch, resultFetchData]
  );

  const fetchCurrentCompany = () => {
    showCompanyRequest({
      dispatch,
      successCallback: response => setCompany(camelCaseEmptyStringRecursive(response.data))
    });
  };

  useEffect(fetchCurrentCompany, []);

  useEffect(() => {
    if (currentClientId) fetchInitialVehicles({ clientId: currentClientId });
  }, [currentClientId, fetchInitialVehicles]);

  useEffect(() => {
    if (currentValues.vehicleId === project.vehicleId) setCurrentVehicle(currentValues.vehicle);
  }, [currentValues, project]);

  const handleClientChange = value => {
    setFieldValue('project[clientId]', value);
    setFieldValue('project[vehicleId]', '');
    if (value !== '') fetchInitialVehicles({ clientId: value });
  };

  const resetVehicle = () => {
    setCurrentVehicle({});
    setVehicleSelected('');
    if (currentValues) setFieldValue('project[vehicleId]', '');
  };

  return (
    <>
      <Form>
        <Row className="change-modal-order mt-3">
          <Col md={3} className={`${!newClient ? 'mobile-mt-3' : ''}`}>
            <ClientTab
              abbr
              modelKey="project"
              currentModel={project}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              values={values}
              clientSelected={clientSelected}
              setClientSelected={setClientSelected}
              companyRequiredFieldAttributes={currentCompany.companyRequiredFieldAttributes}
              currentClient={currentClient}
              setCurrentClient={setCurrentClient}
              newClient={newClient}
              setNewClient={setNewClient}
              clientDisabled={clientDisabled}
              setVehicleDisabled={setVehicleDisabled}
              handleClientChange={handleClientChange}
              newVehicle={newVehicle}
              resetVehicle={resetVehicle}
            />
          </Col>
          <Col md={3} className={`${!newVehicle ? 'mobile-mt-3' : ''}`}>
            <VehicleTab
              abbr={isQuote}
              modelKey="project"
              currentModel={project}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              values={values}
              currentVehicle={currentVehicle}
              setCurrentVehicle={setCurrentVehicle}
              companyRequiredFieldAttributes={currentCompany.companyRequiredFieldAttributes}
              newVehicle={newVehicle}
              setNewVehicle={setNewVehicle}
              vehicleDisabled={vehicleDisabled}
              setVehicleDisabled={setVehicleDisabled}
              vehicleSelected={vehicleSelected}
              setVehicleSelected={setVehicleSelected}
              setClientDisabled={setClientDisabled}
              vehicles={vehicles}
              newClient={newClient}
              resetVehicle={resetVehicle}
            />
          </Col>
          <Col md={3}>
            <Field name="project[mileage]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  inputType="number"
                  label="Kilometraje (opcional)"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={3}>
            <Field name="project[notes]">
              {({ field }) => (
                <BasicTextArea
                  {...field}
                  row="6"
                  label="Motivo / Notas"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>

        {!(newClient || newVehicle) && (
          <Row className="d-flex justify-content-end mt-4">
            <Col md={{ span: 3, offset: 6 }}>
              <Row>
                <Col md={6}>
                  <Button className="btn cancel" variant="primary" block onClick={handleClose}>
                    Cancelar
                  </Button>
                </Col>
                <Col md={6}>
                  <Button type="submit" variant={submitVariant} block onClick={onHide}>
                    {btnMessage}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Form>
      {newClient && (
        <ClientTab
          createClient
          modelKey="project"
          companyRequiredFieldAttributes={currentCompany.companyRequiredFieldAttributes}
          currentModel={project}
          setFieldValue={setFieldValue}
          values={values}
          formSize={{ span: 3, offset: 0 }}
          formClassName="modal-new-form mt-3"
          setClientSelected={setClientSelected}
          setCurrentClient={setCurrentClient}
          setCurrentVehicle={setCurrentVehicle}
          setNewClient={setNewClient}
          setVehicleDisabled={setVehicleDisabled}
          resetVehicle={resetVehicle}
        />
      )}
      {newVehicle && (
        <VehicleTab
          createVehicle
          modelKey="project"
          companyRequiredFieldAttributes={currentCompany.companyRequiredFieldAttributes}
          currentModel={project}
          values={values}
          formSize={{ span: 3, offset: 3 }}
          formClassName="modal-new-form mt-3"
          fetchInitialVehicles={fetchInitialVehicles}
          setClientDisabled={setClientDisabled}
          currentVehicle={currentVehicle || { ...basicVehicle, vtype: 'vehicle' }}
          setCurrentVehicle={setCurrentVehicle}
          setNewVehicle={setNewVehicle}
          setVehicleDisabled={setVehicleDisabled}
          setVehicleSelected={setVehicleSelected}
          vehicles={vehicles}
          resetVehicle={resetVehicle}
        />
      )}
    </>
  );
};

const setInitialValues = ({ project }) => {
  let initialProjectDate = moment().format('DD/MM/yyyy HH:mm');
  let initialPromiseDate = moment().format('DD/MM/yyyy HH:mm');

  initialProjectDate = initialProjectDate && parse(initialProjectDate, 'dd/MM/yyyy HH:mm', new Date());
  initialPromiseDate = initialPromiseDate && parse(initialPromiseDate, 'dd/MM/yyyy HH:mm', new Date());

  return {
    project: {
      ...project,
      projectDate: initialProjectDate,
      promiseDate: initialPromiseDate
    }
  };
};

const validationSchema = Yup.object().shape({
  project: Yup.object().shape({
    clientId: Yup.string().required('Debes seleccionar un Cliente'),
    mileage: Yup.string().nullable(),
    notes: Yup.string().nullable(),
    vehicleId: Yup.string().when('isQuote', {
      is: true,
      then: Yup.string().required('Debes seleccionar un Vehículo'),
      otherwise: Yup.string().nullable()
    })
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ProjectDeskSellForm);
