const fuelTypes = [
  { label: 'Bencina', value: 'petrol' },
  { label: 'Diesel', value: 'diesel' },
  { label: 'Híbrido', value: 'hybrid' },
  { label: 'Eléctrico', value: 'electric' },
  { label: 'Gas Natural Comprimido', value: 'gnc' },
  { label: 'Bencina/GNC', value: 'benzine_gnc' },
];

export default fuelTypes;
