import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';

import { FormikInput, FormikSelect, BasicTextArea, FormikDatePicker, BrandModelSelect } from '../../components';
import fuelTypes from './fuelTypes';
import vtypes from './vtypes';
import { alphaNumericCapitalFormat } from '../../services/utils';
import { getHistoricVehicleRequest } from '../../requests/historicVehicles';

const VehicleFormInputs = ({
  companyRequiredFieldAttributes,
  vehicleTab,
  vehicle,
  errors,
  setFieldValue,
  touched,
  values
}) => {
  const { fuelType, vtype } = vehicle;
  const [currentVtype, setCurrentVtype] = useState(vtype);
  const [lastMaintenance, setLastMaintenance] = useState('');
  const [technicalReviewExpiration, setTechnicalReviewExpiration] = useState('');
  const dispatch = useDispatch();

  const handleSuccessFetch = useCallback(
    response => {
      const vehicleData = camelCaseRecursive(response.data);
      setFieldValue('vehicle[plate]', vehicleData.plate);
      setFieldValue('vehicle[year]', vehicleData.year);
      setFieldValue('vehicle[vehicleBrand]', vehicleData.vehicleBrand);
      setFieldValue('vehicle[vehicleModel]', vehicleData.vehicleModel);
      setFieldValue('vehicle[vehicleBrandId]', vehicleData.vehicleBrand.id);
      setFieldValue('vehicle[vehicleBrandName]', vehicleData.vehicleBrand.name);
      setFieldValue('vehicle[vehicleModelId]', vehicleData.vehicleModel.id);
      setFieldValue('vehicle[vehicleModelName]', vehicleData.vehicleModel.name);
    },
    [setFieldValue]
  );

  const handleEmptyFetch = useCallback(() => {
    console.log('Vehiculo no encontrado');
  }, []);

  const handleAlphaNumericFormatting = e => {
    const formattedValue = alphaNumericCapitalFormat(e.value);
    setFieldValue(e.name, formattedValue);
  };

  const prefillVehicleDataByPlate = plate => {
    if (plate.length === 6) {
      getHistoricVehicleRequest(plate, {
        dispatch,
        successCallback: handleSuccessFetch,
        failureCallback: handleEmptyFetch
      });
    }
  };

  const handleCurrentVtypeShow = () => {
    if (currentVtype) {
      return currentVtype === 'vehicle';
    }
    return vtype === 'vehicle';
  };

  const handleCurrentVtype = data => {
    setCurrentVtype(data.value);
  };

  return (
    <>
      <Col md={12}>
        <Field name="vehicle[plate]">
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              label="Patente/Código"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              onChange={e => {
                const eventTarget = { value: e.target.value, name: e.target.name };
                handleAlphaNumericFormatting(eventTarget);
                prefillVehicleDataByPlate(eventTarget.value);
              }}
            />
          )}
        </Field>
      </Col>
      <Col md={12}>
        <Field name="vehicle[vtype]">
          {({ field }) => (
            <FormikSelect
              {...field}
              abbr
              label="Tipo"
              placeholder="Seleccionar tipo"
              options={vtypes}
              defaultValue={vtype}
              onChange={data => {
                handleCurrentVtype(data);
                setFieldValue(field.name, data ? data.value : '');
              }}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      {handleCurrentVtypeShow() ? (
        <>
          <BrandModelSelect
            vehicleBrandAbbr={currentVtype !== 'component'}
            vehicleModelAbbr={currentVtype !== 'component'}
            vehicleForm
            modelKey="vehicle"
            dispatch={dispatch}
            currentModel={vehicle}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
          />
        </>
      ) : (
        <>
          <Col md={6}>
            <Field name="vehicle[customBrand]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Marca Libre"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="vehicle[customModel]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Modelo Libre"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </>
      )}
      <Col md={12}>
        <Field name="vehicle[vin]">
          {({ field }) => (
            <FormikInput
              {...field}
              abbr={companyRequiredFieldAttributes.vehicleVin}
              label="VIN"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              onChange={e => handleAlphaNumericFormatting({ value: e.target.value, name: e.target.name })}
            />
          )}
        </Field>
      </Col>

      {!vehicleTab && (
        <>
          <Col md={12}>
            <Field name="vehicle[motorNumber]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Serial Motor"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Field name="vehicle[lastMaintenance]">
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  allowPast
                  label="Última Mantención"
                  selected={lastMaintenance}
                  onChange={date => {
                    setLastMaintenance(date);
                    setFieldValue(field.name, date);
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Field name="vehicle[technicalReviewExpiration]">
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  label="Expiración de Revisión"
                  selected={technicalReviewExpiration}
                  onChange={date => {
                    setTechnicalReviewExpiration(date);
                    setFieldValue(field.name, date);
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Field name="vehicle[vehicleType]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Tipo de Vehículo"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </>
      )}
      <Col md={12}>
        <Field name="vehicle[year]">
          {({ field }) => (
            <FormikInput
              {...field}
              inputType="number"
              label="Año"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={6}>
        <Field name="vehicle[fuelType]">
          {({ field }) => (
            <FormikSelect
              {...field}
              isClearable
              label="Tipo de Combustible"
              placeholder="Seleccionar Combustible"
              options={fuelTypes}
              defaultValue={fuelType}
              onChange={data => setFieldValue(field.name, data ? data.value : '')}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={6}>
        <Field name="vehicle[engineCapacity]">
          {({ field }) => (
            <FormikInput
              {...field}
              inputType="number"
              label="Capacidad de Motor (CC)"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      {!vehicleTab && (
        <Col md={12}>
          <Field name="vehicle[notes]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Notas"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      )}
    </>
  );
};

const VehicleForm = props => {
  const {
    action,
    companyRequiredFieldAttributes,
    vehicleTab,
    vehicle,
    errors,
    onHide,
    setFieldValue,
    submitVariant,
    touched,
    values,
    formSize,
    formClassName,
    modelKey
  } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';

  return (
    <Form>
      {vehicleTab ? (
        <Row>
          <Col md={formSize} className={formClassName}>
            <Row>
              <Col xs={6} className="mt-3 mb-2">
                <h3 className={`text-uppercase ${modelKey === 'calendarEvent' ? 'hidden-title' : ''}`}>
                  Crear Vehículo
                </h3>
              </Col>
              <Col xs={6} className={`mt-3 ${modelKey === 'calendarEvent' ? 'modal-save-new-form-btn' : ''}`}>
                <Row>
                  <Col xs={6}>
                    <Button variant="link" onClick={onHide}>
                      Cancelar
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button type="submit" variant="link">
                      Guardar
                    </Button>
                  </Col>
                </Row>
              </Col>
              <VehicleFormInputs
                companyRequiredFieldAttributes={companyRequiredFieldAttributes}
                vehicle={vehicle}
                vehicleTab={vehicleTab}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                values={values}
              />
              <Col xs={12} className={`mt-3 d-flex justify-content-between ${modelKey === 'calendarEvent' ? 'modal-save-new-form-btn' : ''}`}>
                <Button variant="secondary" onClick={onHide}>
                  Cancelar
                </Button>
                <Button type="submit">
                  Guardar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <VehicleFormInputs
              companyRequiredFieldAttributes={companyRequiredFieldAttributes}
              vehicle={vehicle}
              vehicleTab={vehicleTab}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              values={values}
            />
          </Row>
          <Row className="d-flex justify-content-end mb-3">
            <Col md={2}>
              <Button type="submit" variant={submitVariant} block onClick={onHide}>
                {btnMessage}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

const setInitialValues = props => {
  const { vehicle } = props;
  return {
    vehicle: {
      ...vehicle,
      customBrand: vehicle.customBrand || '',
      customModel: vehicle.customModel || '',
      motorNumber: vehicle.motorNumber || '',
      notes: vehicle.notes || '',
      plate: vehicle.plate || '',
      engineCapacity: vehicle.engineCapacity || '',
      vehicleType: vehicle.vehicleType || '',
      vin: vehicle.vin || '',
      vtype: vehicle.vtype || '',
      year: vehicle.year || ''
    }
  };
};

const minimumVehicle = {
  customBrand: Yup.string().when('vtype', {
    is: 'component',
    then: Yup.string().required('Debes ingresar una marca'),
    otherwise: Yup.string().nullable()
  }),
  customModel: Yup.string().when('vtype', {
    is: 'component',
    then: Yup.string().required('Debes ingresar un modelo'),
    otherwise: Yup.string().nullable()
  }),
  engineCapacity: Yup.string().nullable(),
  fuelType: Yup.string().nullable(),
  lastMaintenance: Yup.string().nullable(),
  motorNumber: Yup.string().nullable(),
  notes: Yup.string().nullable(),
  plate: Yup.string().required('Debes ingresar una patente'),
  technicalReviewExpiration: Yup.string().nullable(),
  vehicleBrandId: Yup.string().when('vtype', {
    is: 'vehicle',
    then: Yup.string().required('Debes seleccionar una marca'),
    otherwise: Yup.string().nullable()
  }),
  vehicleModelId: Yup.string().when('vtype', {
    is: 'vehicle',
    then: Yup.string().required('Debes seleccionar un modelo'),
    otherwise: Yup.string().nullable()
  }),
  year: Yup.string()
    .matches(/^[0-9]{4}$/, 'Debes ingresar 4 dígitos')
    .nullable(),
  vin: Yup.string().matches(/^[0-9a-zA-Z]{17}$/, 'VIN debe tener 17 caracteres')
};

const fullVehicle = {
  ...minimumVehicle,
  vin: Yup.string()
    .matches(/^[0-9a-zA-Z]{17}$/, 'VIN debe tener 17 caracteres')
    .required('Debes ingresar un VIN')
};

const validationSchema = ({ companyRequiredFieldAttributes }) => {
  return Yup.object().shape({
    vehicle: Yup.object().shape(companyRequiredFieldAttributes.vehicleVin ? fullVehicle : minimumVehicle)
  });
};

const handleSubmit = (values, { props }) => {
  const { formRequest, onHide } = props;
  formRequest(values);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(VehicleForm);
