const projectPaymentTypes = [
  { label: 'Cheque', value: 'check' },
  { label: 'Efectivo', value: 'cash' },
  { label: 'Getnet', value: 'getnet' },
  { label: 'Mercado Pago', value: 'payment_market' },
  { label: 'Nota Crédito Devolución', value: 'note_credit_return' },
  { label: 'Tarjeta Crédito', value: 'credit_card' },
  { label: 'Tarjeta Débito', value: 'debit_card' },
  { label: 'Transferencia Bancaria', value: 'transfer' },
  { label: 'Webpay', value: 'webpay' }
];

export default projectPaymentTypes;
