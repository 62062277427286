import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ComponentDataTable from '../../components/Utils/DataTable';
import { DefaultModal } from '../../components';
import {
  companyIndexVehiclesRequest,
  debounceCompanyIndexVehiclesRequest,
  deleteVehicleRequest
} from '../../requests/vehicles';
import { sendAlert } from '../../actions/utils';
import downloadFile from '../../services/utils';

const VehicleCompanyDataTable = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [amount, setAmount] = useState(0);
  const [vehicles, setVehicles] = useState([]);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const { columns, clientId } = props;
  const [queries, setQueries] = useState({});

  const handleSuccessRequest = response => {
    const responseVehicles = response.data.data;
    const { amount: responseAmount } = response.data.metadata;
    setVehicles(responseVehicles);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleFailureRequest = error => {
    const {
      data: { message }
    } = error.response;
    dispatch(sendAlert({ kind: 'error', message }));
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    debounceCompanyIndexVehiclesRequest({
      dispatch,
      params,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/clients/${item.client.value}/vehicles/${item.id}`);
        break;
      case 'edit':
        history.push(`/clients/${item.client.value}/vehicles/${item.id}/edit`);
        break;
      case 'destroy':
        setModalShow(true);
        setModalItem(item);
        setModalBody('¿Estás seguro que deseas eliminar este vehículo?');
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleSuccessRemove = () => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Vehículo eliminado con éxito' }));
    setMoreData(!moreData);
  };

  const removeVehicle = vehicleId => {
    deleteVehicleRequest(clientId, vehicleId, {
      dispatch,
      successCallback: handleSuccessRemove
    });
  };

  const handleModalConfirm = () => {
    removeVehicle(modalItem.client?.value, modalItem.id);
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'vehicle_brand_name':
        return { sort_vehicle_brand: name };
      case 'vehicle_model_name':
        return { sort_vehicle_model: name };
      case 'client_full_name':
        return { sort_client: name };
      default:
        return { sort_column: name };
    }
  };

  const handleSuccessDownload = response => {
    downloadFile(response);
  };

  const downloadIndex = (format, params) => {
    companyIndexVehiclesRequest({
      dispatch,
      params: { ...params, display_length: 100000 },
      format,
      successCallback: handleSuccessDownload
    });
  };
  return (
    <>
      <Row className="justify-content-end">
        <Col md={2}>
          <Button variant="secondary" block onClick={() => downloadIndex('xlsx', queries)}>
            Exportar
          </Button>
        </Col>
      </Row>
      <ComponentDataTable
        className="w-100"
        onRequest={onRequest}
        columns={columns(handleButtonClick, setMoreData)}
        handleSortCase={sortColumnCase}
        data={vehicles}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={response => {
          setQueries(response);
          if (!onRequest) handleIndexRequest(response);
        }}
      />
      <DefaultModal
        title="Eliminar Vehículo"
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={handleModalConfirm}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default VehicleCompanyDataTable;
