import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import { es } from 'date-fns/locale';
import moment from "moment/moment";
import { SimpleCenteredModal } from "../../components";
import CalendarEventForm from "../Calendar/Public/CalendarEventForm";
import { saveCurrentClient, saveSecondCurrentClient, sendAlert } from "../../actions/utils";
import { useFindCompany, useGetSchedulesCompany } from "../../hooks/usePublicCompanies";
import {useCreateCalendarEvents} from "../../hooks/usePublicCalendarEvents";

const ScheduleAppointment = ({ match }) => {
  const { companyCode } = match.params;
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { company } = useFindCompany(companyCode);
  console.log(company);
  const { schedules } = useGetSchedulesCompany(companyCode, format(selectedDate, 'yyyy-MM-dd'));
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [dialogClassName, setDialogClassName] = useState('');
  const dispatch = useDispatch();
  const { create: createCalendarEvent } = useCreateCalendarEvents(companyCode);

  const setModal = (title, body) => {
    dispatch(saveCurrentClient({}));
    dispatch(saveSecondCurrentClient({}));
    setModalTitle(title);
    setModalBody(body);
    setModalShow(true);
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleSuccessAction = (params) => {
    handleModalClose();
    setDialogClassName('');
    setModalBody(
      <Row>
        <Col md={12} className="mb-3">
          <h2>Cita Agendada con Éxito!</h2>
        </Col>
        <Col md={12}>
          <p>Fecha: {format(params.calendarEvent.startDate, "eeee, dd 'de' MMMM 'del' yyyy", {locale: es})}</p>
          <p>Hora: {format(params.calendarEvent.startDate, 'HH:mm')}</p>
        </Col>
      </Row>
    );
    setModalShow(true);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleUpdateRequest = params => {
    createCalendarEvent(params, { onSuccess: () => handleSuccessAction(params), onError: handleFailureRequest });
  };

  const formatSlot = slot => {
    const [hours, minutes] = slot.split(":");
    const combinedDate = moment(selectedDate).set({
      hour: parseInt(hours),
      minute: parseInt(minutes)
    });
    return combinedDate.toDate();
  }

  const handleModal = (event, slot) => {
    setDialogClassName('modal-90w');
    setModal('Crear Cita', <CalendarEventForm
      calendarEvent={{dateSelected: selectedDate, startDate: formatSlot(slot)}}
      action="new"
      formRequest={handleUpdateRequest}
      submitVariant="success"
      handleClose={handleModalClose}
    />);
  };

  const disableWeekends = ({ date, view }) => {
    return (view === 'month' && company.disabledDays?.includes(date.getDay())) ||
      format(date, 'yyyy-MM-dd') < format(new Date(), 'yyyy-MM-dd');
  };

  return (
    <Container>
      <Row className="justify-content-center align-items-center mt-5">
        <Col md={12} className="mt-5">
          <h1>Agendar cita</h1>
        </Col>
        <Col md={12} className="mt-4 mb-5">
          <div>{company?.appointmentDescription}</div>
        </Col>
        <Col md={4}>
          <h3>Seleccione fecha</h3>
          <Calendar
            onChange={setSelectedDate}
            value={selectedDate}
            tileDisabled={disableWeekends}
          />
        </Col>
        <Col md={8}>
          <div className="font-weight-bold mb-2">Fecha seleccionada: {format(selectedDate, 'dd/MM/yyyy')}</div>
          <div className="font-weight-bold mb-2">Elija una hora</div>
          <Row>
            {schedules.map((slot, index) => (
              <Col xs={6} md={4} lg={3} key={index}>
                <Button variant="outline-primary" block onClick={(event) => handleModal(event, slot)}>
                  {slot}
                </Button>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <SimpleCenteredModal
        closeButton
        dialogClassName={dialogClassName}
        classNameBody=""
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        size=""
        onHide={handleModalClose}
        centered={false}
      />
    </Container>
  );
};

export default ScheduleAppointment;
