import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatToPrice, toInteger, toDecimal } from '../../services/utils';

const ProjectWorkHourReportShow = ({ data, onRequest }) => {
  if (onRequest) {
    return (
      <Row>
        <Col className="text-center pt-2" md={{ span: 10, offset: 1 }} xs={12}>
          <p>Cargando</p>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row>
        <Col className="text-center pt-2" md={{ span: 10, offset: 1 }} xs={12} style={{ backgroundColor: '#f7f7f7' }}>
          <h1>{data.length !== 0 ? 'Reporte de Horas' : ''}</h1>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }} style={{ backgroundColor: '#f7f7f7' }}>
          <Row className="mt-2 mb-2">
            <Col>
              <strong>Fecha</strong>
            </Col>
            <Col>
              <strong>Código del proyecto</strong>
            </Col>
            <Col>
              <strong>Patente/Código del Vehículo</strong>
            </Col>
            <Col>
              <strong>Nombre del trabajo</strong>
            </Col>
            <Col>
              <strong>Responsable</strong>
            </Col>
            <Col>
              <strong>Horas Registradas</strong>
            </Col>
            <Col>
              <strong>Horas Aprobadas</strong>
            </Col>
            <Col>
              <strong>Precio por Hora</strong>
            </Col>
            <Col>
              <strong>Total</strong>
            </Col>
            <Col>
              <strong>Cantidad de Horas Cobradas</strong>
            </Col>
            <Col>
              <strong>Precio de Venta Neto</strong>
            </Col>
            <Col>
              <strong>Margen</strong>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }} xs={14} style={{ backgroundColor: '#f7f7f7', padding: '0px' }}>
          <Table striped bordered hover>
            <tbody>
              {data.map(item => {
                return (
                <tr key={item.id} className="mt-4">
                  <td style={{width: '8.33%'}}>{item.parsedDate}</td>
                  <td style={{width: '8.33%'}}>
                    <Link to={`/projects/${item.project.code}`}>{item?.project.code}</Link>
                  </td>
                  <td style={{width: '8.33%'}}>{item.plate}</td>
                  <td style={{width: '8.33%'}}>{item.projectWorkName}</td>
                  <td style={{width: '8.33%'}}>{item.accountableUser.fullName}</td>
                  <td style={{width: '8.33%'}}>{toDecimal(item.time, 2)}</td>
                  <td style={{width: '8.33%'}}>{toDecimal(item.approvedTime, 2)}</td>
                  <td style={{width: '8.33%'}}>{formatToPrice(toInteger(item.globalHourPrice))}</td>
                  <td style={{width: '8.33%'}}>{formatToPrice(item.total)}</td>
                  <td style={{width: '8.33%'}}>{toDecimal(item.quantity)}</td>
                  <td style={{width: '8.33%'}}>{formatToPrice(item.netWorth)}</td>
                  <td style={{width: '8.33%'}}>{formatToPrice(item.margin)}</td>
                </tr>
              )})}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col md={{ span: 10, offset: 1 }} style={{ backgroundColor: '#f7f7f7' }}>
          <Row className="mb-2">
            <Col />
            <Col />
            <Col />
            <Col />
            <Col />
            <Col>
              <strong> Subtotal </strong>
            </Col>
            <Col>
              <strong>
                {' '}
                {toDecimal(
                  data.reduce((n, { time }) => n + time, 0),
                  2
                )}
              </strong>
            </Col>
            <Col />
            <Col>
              <strong>
                {formatToPrice(toInteger(data.reduce((n, { globalHourPrice, time }) => n + time * globalHourPrice, 0)))}
              </strong>
            </Col>
            <Col />
            <Col>
              <strong>{formatToPrice(toInteger(data.reduce((n, { valueCharged }) => n + valueCharged, 0)))}</strong>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ProjectWorkHourReportShow;
