import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn } from 'formik';
import { FormikCheckBox, FormikInput, FormikNumberFormat } from '../../components';
import useCountryFactory from "../../hooks/useCountryFactory";
import PhoneInputField from "../../components/Utils/Input/PhoneInputField";

const ContactInput = ({ index, errors, touched, parent, setFieldValue }) => {
  const countryFactory = useCountryFactory();
  return (
    <Col>
      <Row>
        <Col md={6} className="pr-0">
          <Field name={`${parent}[contactsAttributes][${index}][name]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} className="pr-0">
          <Field name={`${parent}[contactsAttributes][${index}][email]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Email"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={12} className="pr-0">
          <Field name={`${parent}[contactsAttributes][${index}][charge]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                label="Cargo"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} className="pr-0">
          <PhoneInputField
            abbr
            name={`${parent}[contactsAttributes][${index}][cellphone]`}
            label="Celular"
          />
        </Col>
        <Col md={6} className="pr-0">
          <Field name={`${parent}[contactsAttributes][${index}][phone]`}>
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                label="Teléfono"
                format={countryFactory.formatPhone}
                onValueChange={number => setFieldValue(field.name, number.formattedValue)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col className="font-weight-bold mb-2">
          ¿Qué notificaciones debe recibir esta persona?
        </Col>
        <Col md={12}>
          <Field name={`${parent}[contactsAttributes][${index}][isBiller]`}>
            {({ field }) => (
              <FormikCheckBox
                {...field}
                field={field}
                label="Facturación"
                custom
              />
            )}
          </Field>
        </Col>
      </Row>
    </Col>
  )
};

export default ContactInput;
