import React from 'react';
import ContactsShow from '../Contact/ContactsShow';
import useCountryFactory from "../../hooks/useCountryFactory";

const ProviderInfo = props => {
  const {
    provider: {
      active,
      businessActivity,
      cellphone,
      contactsAttributes,
      email,
      fullAddress,
      nationalIdentification,
      legalName,
      name,
      notes,
      phone,
      website
    }
  } = props;
  const countryFactory = useCountryFactory();

  return (
    <>
      <div className="show-info-container">
        <div className="show-info-small-container">
          <div className="show-info-column">
            <p>
              <strong>{countryFactory.getLabelForCompanyIdentification()}:</strong> {nationalIdentification}
            </p>
            <p>
              <strong>Nombre:</strong> {name}
            </p>
            <p>
              <strong>Razón Social:</strong> {legalName}
            </p>
            <p>
              <strong>Actividad Comercial:</strong> {businessActivity}
            </p>
            <p>
              <strong>Dirección:</strong> {fullAddress}
            </p>
            <p>
              <strong>Activo:</strong> {active}
            </p>
          </div>
          <div className="show-info-column">
            <p>
              <strong>Email:</strong> {email}
            </p>
            <p>
              <strong>Sitio Web:</strong> {website}
            </p>

            <p>
              <strong>Teléfono:</strong> {phone}
            </p>
            <p>
              <strong>Celular:</strong> {cellphone}
            </p>
          </div>
        </div>
        <div className="show-info-small-container mt-4">
          <div className="show-info-column">
            <p>
              <strong>Notas:</strong> {notes}
            </p>
          </div>
        </div>
        <ContactsShow contactsAttributes={contactsAttributes} />
      </div>
    </>
  );
};

export default ProviderInfo;
