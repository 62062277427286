import {Button, Table} from 'react-bootstrap';
import React, {Fragment, useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {ShoppingCart} from "react-feather";
import ReactTooltip from "react-tooltip";
import snakeCaseKeys from "snakecase-keys";
import {useDispatch} from "react-redux";
import { Formik, Form, Field, getIn } from 'formik';
import ApproveButtons from './ApproveButtons';
import PurchaseReceivedForm from "../../ProjectDetail/PurchaseReceivedForm";
import {updateProjectDetailRequest} from "../../../requests/projectDetails";
import {publicUpdateProjectWorksApprovalStatusRequest} from "../../../requests/projects";
import {SimpleCenteredModal, BasicTextArea} from "../../../components";
import {sendAlert} from "../../../actions/utils";

const ProjectQuoteTable = ({publicVersion, project, handleAdvancedConfiguration, setMoreData, token, version}) => {
  const {
    companyTaxRate,
    parsedTotalDiscount,
    parsedTotalPriceWithDiscount,
    parsedTotalPrice,
    parsedTotalPriceTax,
    parsedTotalTax,
    projectWorksAttributes
  } = project;
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [doubtModalShow, setDoubtModalShow] = useState(null);
  const [totalApproved, setTotalApproved] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setTotalApproved(project.projectWorksAttributes.filter(w => w.approvalStatus === 'approved').reduce((acc, current) => {
      const parsedPrice = parseFloat(current.projectDetailsAttributes.reduce((accDetail, currentDetail) => {      
        const parsedDetailPrice = parseFloat(currentDetail.price) || 0;
        return accDetail + parsedDetailPrice;
      }, 0));
      return acc + parsedPrice;
    }, 0));
  },[project.projectWorksAttributes]);

  const updateTotalApproved = (projectWorksApprovals) => {
    const newTotalApproved = project.projectWorksAttributes.filter((w, i) => projectWorksApprovals[i].value === 'approved').reduce((acc, current) => {
      const parsedPrice = parseFloat(current.projectDetailsAttributes.reduce((accDetail, currentDetail) => {      
        const parsedDetailPrice = parseFloat(currentDetail.price) || 0;
        return accDetail + parsedDetailPrice;
      }, 0));
      return acc + parsedPrice;
    }, 0);
    setTotalApproved(Math.round(newTotalApproved*1.19));
  }

  const projectWorksBody = (values, setFieldValue) =>
    projectWorksAttributes &&
    projectWorksAttributes.map((projectWork, indexWork) => {
      const projectDetails = projectWork.projectDetailsAttributes.filter(projectDetail => !projectDetail.hidden);
      return (
        <Fragment key={`project-work-${indexWork.toString()}`}>
          {projectDetails.map((projectDetail, indexDetail) =>
            indexDetail === 0 ? (
              <>
                <tr>
                  {publicVersion && project.currentOtState === 'budget_sent' &&
                    <>
                      <td>
                        {getIn(values, `projectWorksApprovals[${indexWork}][disabled]`) ? (
                          projectWork.approvalStatus === 'approved' ? 
                          <strong className='text-success'>APROBADO</strong> : 
                          <strong className='text-danger'>RECHAZADO</strong>
                        ) : (
                          <>
                          <Field name={`projectWorksApprovals[${indexWork}][value]`}>
                          {({ field }) =>
                            <ApproveButtons
                              handleButtonsAction={(data) => {
                                const projectWorksApprovals = getIn(values, `projectWorksApprovals`)
                                projectWorksApprovals[indexWork].value = data;
                                if (data === 'doubt') {                                  
                                  setDoubtModalShow(indexWork);
                                }
                                updateTotalApproved(projectWorksApprovals);
                                setFieldValue(`projectWorksApprovals`, projectWorksApprovals);
                              }}
                              field={field}
                            />
                          }
                          </Field>
                          <SimpleCenteredModal
                            title="Dudas"
                            body={
                              <Field name={`projectWorksApprovals[${indexWork}][notes]`}>
                                {({ field }) => (
                                  <BasicTextArea
                                    {...field}
                                  />
                                )}
                              </Field>
                            }
                            show={doubtModalShow === indexWork}
                            onHide={() => setDoubtModalShow(null)}
                            closeButton
                          />
                          </>
                        )}                        
                      </td>
                    </>
                  }
                  <td className="font-weight-bold" style={{color: '#5e99b8'}}>
                    {projectWork.name}
                  </td>
                </tr>
                <tr key={`project-detail-${indexDetail.toString()}`}>
                  {projectDetailBody(projectDetail, indexWork, indexDetail)}
                </tr>
              </>
            ) : (
              <tr key={`project-detail-${indexDetail.toString()}`}>
                {projectDetailBody(projectDetail, indexWork, indexDetail)}
              </tr>
            )
          )}
          {publicVersion && projectDetails.length > 0 && (
            <tr>
              <th colSpan={publicVersion ? (project?.hideSubcategory ? '5' : '6') : '6'}/>
              <th>Subtotal</th>
              <th>{currencyFormat(projectDetails)}</th>
            </tr>
          )}
        </Fragment>
      );
    });

  const calculatedPrice = projectDetailsAttributes => {
    return projectDetailsAttributes.reduce((acc, current) => {
      const parsedPrice = parseFloat(current.price) || 0;
      return acc + parsedPrice;
    }, 0);
  };

  const currencyFormat = (projectDetailsAttributes, sym = '$') => {
    const price = calculatedPrice(projectDetailsAttributes);
    const priceWithFormat = price.toLocaleString().replace(',', '.');
    return `${sym}${priceWithFormat}`;
  };

  const isDiscounted = () => {
    if (parsedTotalDiscount) {
      const discount = parseInt(parsedTotalDiscount.substring(1), 10);
      if (discount) return true;
    }
    return false;
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleSuccessUpdateRequest = () => {
    dispatch(sendAlert({kind: 'success', message: 'Detalle actualizado con éxito'}));
    setMoreData(m => !m);
    handleModalClose();
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({kind: 'error', message: error?.response?.data?.message}));
  };

  const basicPurchaseReceived = {
    purchaseReceivedDate: new Date(),
    imagesInfo: [],
    projectContactsAttributes: []
  };

  const handleUpdateRequest = params => {
    updateProjectDetailRequest(params.id, {
      dispatch,
      params: {project_detail: snakeCaseKeys(params, { exclude: ['_destroy'] })},
      formData: true,
      successCallback: handleSuccessUpdateRequest,
      failureCallback: handleFailureRequest
    });
  };

  const projectDetailBody = (vProjectDetail, indexWork, indexDetail) => {
    const {
      categoryName,
      cost,
      parsedCost,
      parsedTotalCost,
      parsedMargin,
      parsedUnitPrice,
      parsedPrice,
      productCategoryName,
      productId,
      productNameShow,
      quantity,
      parsedPriceDiscount
    } = vProjectDetail;
    const inputName = `project[projectWorksAttributes][${indexWork}][projectDetailsAttributes][${indexDetail}]`;
    const nameToShow = productNameShow;

    const openModal = () => {
      const id = vProjectDetail?.id;
      const imagesInfo = vProjectDetail?.purchaseReceivedImagesInfo || [];
      const date = vProjectDetail?.purchaseReceivedDate;
      const projectContactsAttributes = vProjectDetail?.projectContactsAttributes || [];
      let purchaseReceivedDate = new Date();
      if (date) {
        purchaseReceivedDate = new Date(date);
      }
      setModalBody(
        <PurchaseReceivedForm
          formRequest={handleUpdateRequest}
          handleModalClose={handleModalClose}
          submitVariant="success"
          disabled={date}
          purchaseReceived={{...basicPurchaseReceived, id, imagesInfo, purchaseReceivedDate, projectContactsAttributes}}
          projectId={project?.id}
        />
      );
      setModalShow(true);
    };
    return (
      <>
        {publicVersion && project.currentOtState === 'budget_sent' && <td></td>}
        <td>
          {!publicVersion && vProjectDetail?.hasPurchaseReceived && <>
            <ShoppingCart data-tip data-for={`purchase-received-${vProjectDetail?.id}`} className="mr-1"
                        width="15px" height="15px" color={vProjectDetail?.purchaseReceivedDate ? "#5CC689" : "black"}
                        onClick={openModal}/>
            <ReactTooltip id={`purchase-received-${vProjectDetail?.id}`} place="top">
              {vProjectDetail?.purchaseReceivedDate ? "Compra recibida" : "Compra no recibida"}
            </ReactTooltip>
          </>}
          {categoryName}
        </td>
        {!project?.hideSubcategory && <td>{productCategoryName}</td>}
        {publicVersion ? (
          <td className={`text-uppercase ${nameToShow ? '' : 'font-italic text-muted'}`}>
            {nameToShow || 'Ítem sin Nombre'}
          </td>
        ) : (
          <td className={nameToShow ? '' : 'font-italic text-muted'}>
            <Link className="text-uppercase" to={`/products/${productId}`}>
              {nameToShow || 'Ítem sin Nombre'}
            </Link>
          </td>
        )}
        {!publicVersion && (
          <td>
            <div
              className="text-primary"
              role="button"
              onClick={() => handleAdvancedConfiguration(vProjectDetail, inputName, productId)}
            >
              {parsedCost}
            </div>
          </td>
        )}
        <td>{quantity || 0}</td>
        {!publicVersion && <td>{parsedTotalCost}</td>}
        <td>{parsedUnitPrice}</td>
        <td className="text-warning">{parsedPriceDiscount}</td>
        <td>{parsedPrice}</td>
        {!publicVersion && <td>{cost ? parsedMargin : 'No aplica'}</td>}
      </>
    );
  };

  const handleSubmit = (values) => {
    if (values.projectWorksApprovals.filter(v => v.value === 'pending').length) {
      dispatch(sendAlert({kind: 'error', message: 'Se deben seleccionar todos.'}));
    } else {
      const updatedValues = values.projectWorksApprovals.filter(v => !v.disabled);
      updatedValues.forEach(uv => {
        if (uv.value === 'doubt' && (!uv.notes || uv.notes === '') && values.all.notes && values.all.notes !== '') {
          uv.notes = values.all.notes;
        }
      });
      publicUpdateProjectWorksApprovalStatusRequest(token, version, {
        dispatch,
        params: {project: {project_works_approval_status: updatedValues}},
        successCallback: (response) => {
          dispatch(sendAlert({kind: 'success', message: 'Enviado con éxito'}));
          history.push(response.data.path);
        },
        failureCallback: handleFailureRequest
      });
    }
  }

  return (
    <>
      <Formik 
        initialValues={ publicVersion && project.currentOtState === 'budget_sent' ? 
          { 
            projectWorksApprovals: projectWorksAttributes.map(work => {
              return {
                id: work.id, 
                value: work.approvalStatus ? work.approvalStatus : 'pending', 
                disabled: ['approved', 'rejected'].includes(work.approvalStatus)
              }
            })
          } : {}
        } 
        onSubmit={(values) => {handleSubmit(values)}}
      >
      {({ values, setFieldValue }) => (
        <Form>
          <Table responsive borderless hover>
            <thead className="border-top-borderless-table">
            <tr className="header-border-bottom">
              {publicVersion && project.currentOtState === 'budget_sent' && <th>Aprobación</th>}
              <th>Categoría</th>
              {!project?.hideSubcategory && <th>Subcategoría</th>}
              <th>Ítem</th>
              {!publicVersion && <th>Costo Neto Unit</th>}
              <th>Cantidad</th>
              {!publicVersion && <th>Costo Neto Total</th>}
              <th>P. Venta Unit</th>
              <th>Descuento</th>
              <th>P. Venta Total</th>
              {!publicVersion && <th>Margen</th>}
            </tr>
            </thead>
            <tbody>
            {projectWorksBody(values, setFieldValue)}
            {publicVersion && (
              <>
                <tr className="border-top-borderless-table">
                  <th colSpan={publicVersion ? (project?.hideSubcategory ? '5' : '6') : '6'}/>
                  <th>Valor Neto</th>
                  <th>{parsedTotalPrice}</th>
                </tr>
                {isDiscounted() && (
                  <>
                    <tr className="font-weight-bold">
                      <th colSpan={publicVersion ? (project?.hideSubcategory ? '5' : '6') : '6'}/>
                      <th>Descuento</th>
                      <th className="text-secondary">- {parsedTotalDiscount}</th>
                    </tr>
                    <tr className="font-weight-bold">
                      <th colSpan={publicVersion ? (project?.hideSubcategory ? '5' : '6') : '6'}/>
                      <th>Valor Total Neto</th>
                      <th>{parsedTotalPriceWithDiscount}</th>
                    </tr>
                  </>
                )}
                <tr>
                  <th colSpan={publicVersion ? (project?.hideSubcategory ? '5' : '6') : '6'}/>
                  <th>IVA ({companyTaxRate})</th>
                  <th>{parsedTotalTax}</th>
                </tr>
                <tr>
                  <th colSpan={publicVersion ? (project?.hideSubcategory ? '5' : '6') : '6'}>
                    {publicVersion && project.currentOtState === 'budget_sent' &&
                    <>
                      <Field name={`all`}>
                        {({ field }) =>
                          <ApproveButtons
                            handleButtonsAction={(data) => {
                              const projectWorksApprovals = getIn(values, 'projectWorksApprovals')
                              projectWorksApprovals.filter(pwa => !pwa.disabled).forEach(pwa => pwa.value = data);
                              updateTotalApproved(projectWorksApprovals);
                              setFieldValue('projectWorksApprovals', projectWorksApprovals);
                              if (data === 'doubt') {                                  
                                setDoubtModalShow('all');
                              }
                            }}
                            field={field}
                          />
                        }
                      </Field>
                      <SimpleCenteredModal
                        title="Dudas"
                        body={
                          <Field name={`all[notes]`}>
                            {({ field }) => (
                              <BasicTextArea
                                {...field}
                              />
                            )}
                          </Field>
                        }
                        show={doubtModalShow === 'all'}
                        onHide={() => setDoubtModalShow(null)}
                        closeButton
                      />
                    </>
                    }
                  </th>
                  <th>
                    <h2>Total</h2>
                  </th>
                  <th>
                    <h2>{parsedTotalPriceTax}</h2>
                  </th>
                </tr>
                {publicVersion && project.currentOtState === 'budget_sent' &&
                <tr>
                  <th colSpan={publicVersion ? (project?.hideSubcategory ? '5' : '6') : '6'}>
                    <Button variant="primary" type='submit' className='text-center'>Enviar Aprobaciones</Button>
                  </th>
                  <th>
                    <h2 className='text-success'>Total aprobado</h2>
                  </th>
                  <th>
                    <h2 className='text-success'>{`$${totalApproved.toLocaleString().replaceAll(',', '.')}`}</h2>
                  </th>
                </tr>
                }
              </>
            )}
            </tbody>
          </Table>
        </Form>
      )}
      </Formik>
      <SimpleCenteredModal
        size="lg"
        title="Registro de compra recibida"
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
        closeButton
      />
    </>
  );
};
export default ProjectQuoteTable;
