import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { BasicTextArea, FormikCheckBox, FormikInput, FormikNumberFormat } from '../../components';
import { updateAdvancedConfigurationRequest } from '../../requests/projects';

const ProjectDetailAdvancedConfiguration = ({ onHide, originalValues, setOriginalValues, ...props }) => {
  const { inputName, handleEditConfirmation, restoreOriginalValues, vProjectDetail } = props;
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const {
    cost,
    movementDetails,
    quantity,
    product: vProduct,
    productId: vProductId,
    requestPurchase: vRequestPurchase
  } = vProjectDetail;

  const [allDisabled, setAllDisabled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(!vRequestPurchase);
  const { hasOnePurchase, purchaseMade, requestPurchase } = originalValues;
  const project = getIn(values, 'project');
  const dispatch = useDispatch();

  const handleInitialValues = () => {
    if (hasOnePurchase && purchaseMade && requestPurchase) setAllDisabled(true);
  };

  useEffect(handleInitialValues, []);

  const handlePurchaseCheckbox = () => {
    setIsDisabled(!vRequestPurchase);
    if (!vRequestPurchase) setFieldValue(`${inputName}[purchaseMade]`, false);
  };

  useEffect(handlePurchaseCheckbox, [vRequestPurchase]);

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Configuración actualizada con éxito' }));
    setOriginalValues(vProjectDetail);
    onHide();
  };

  const handleUpdateRequest = () => {
    updateAdvancedConfigurationRequest(project.id, {
      dispatch,
      params: snakeCaseKeys(values),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <Row>
      <Col>
        <FormikInput disabled label="Nombre / Código" value={vProduct?.label || vProjectDetail?.productNameCustom} />
      </Col>
      {!vProductId && (
        <Col md={6}>
          <Field name={`${inputName}[cost]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                label="Costo Unitario"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      )}
      {vProductId && (
        <>
          <Col md={6}>
            <FormikInput disabled label="Nombre Venta" value={vProduct?.saleName} />
          </Col>
          <Col md={4}>
            <FormikNumberFormat disabled leftAddon="$" label="Costo Unitario" value={parseInt(vProduct?.cost, 10)} />
          </Col>
          <Col md={4}>
            <FormikNumberFormat
              disabled
              leftAddon="$"
              label="Costo Cotización"
              value={parseInt(vProduct?.costQuotation, 10)}
            />
          </Col>
          <Col md={4}>
            <FormikNumberFormat
              disabled
              leftAddon="$"
              label="Costo Total"
              value={vProduct?.cost * quantity || cost * quantity || 0}
            />
          </Col>
        </>
      )}
      <Col md={12}>
        <Field name={`${inputName}[notes]`}>
          {({ field }) => (
            <BasicTextArea
              {...field}
              row="3"
              label="Notas"
              customClass="two-lines"
              placeholder="Agregar nota"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={12} className="mt-2">
        <Row>
          <Col md={6}>
            <Field name={`${inputName}[requestPurchase]`}>
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  field={field}
                  label="Solicitar compra"
                  customClass="pl-2"
                  disabled={allDisabled || hasOnePurchase}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name={`${inputName}[purchaseMade]`}>
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  field={field}
                  label="Compra realizada"
                  customClass="pl-2"
                  disabled={allDisabled || isDisabled || (hasOnePurchase && purchaseMade)}
                  onChange={e => {
                    field.onChange(e);
                    if (e.target.checked) {
                      setFieldValue(`${inputName}[purchaseDate]`, new Date().toLocaleDateString('es-ES'));
                    } else {
                      setFieldValue(`${inputName}[purchaseDate]`, '');
                    }
                  }}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Col>
      {movementDetails.length > 0 && (
        <Col md={12}>
          <h4 className="text-uppercase text-center my-3">Historial</h4>
          <Table responsive borderless hover>
            <thead className="border-top-borderless-table">
              <tr className="header-border-bottom">
                <th>Fecha de Movimiento</th>
                <th>Tipo</th>
                <th>Cantidad</th>
                <th>Valor Unitario</th>
              </tr>
            </thead>
            <tbody>
              {movementDetails.map((movementDetail, index) => (
                <tr key={`movement-detail-${index.toString()}`}>
                  <td>{movementDetail?.createdAt}</td>
                  <td>{movementDetail?.translatedMovementType}</td>
                  <td>{movementDetail?.quantity || 0}</td>
                  <td>{movementDetail?.parsedSalePrice}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      )}
      <Col md={12} className="mt-3">
        <Row>
          <Col md={4}>
            <Button block variant="outline-dark" onClick={restoreOriginalValues}>
              Cancelar
            </Button>
          </Col>
          {vProductId && (
            <Col md={4}>
              <Button block variant="secondary" onClick={handleEditConfirmation}>
                Editar ítem
              </Button>
            </Col>
          )}
          {(!allDisabled || !vProductId) && (
            <Col md={!vProductId ? { span: 4, offset: 4 } : 4}>
              <Button block variant="primary" onClick={handleUpdateRequest}>
                Guardar
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default ProjectDetailAdvancedConfiguration;
