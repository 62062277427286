import CountryFactory from "./CountryFactory";

export default class ArCountryFactory extends CountryFactory {
  get formatPhone() {
    return "+54 # ## ####-####";
  }

  handleNationalIdentificationFormat(e, setFieldValue, isForeign) {
    if (isForeign) {
      setFieldValue(e.target.name, e.target.value);
    } else {
      const onlyNumbers = e.target.value.replace(/\D/g, '');
      const numberFormatted = onlyNumbers !== '' ? Number(onlyNumbers).toLocaleString('es-AR') : '';
      setFieldValue(e.target.name, numberFormatted);
    }
  }

  isValidRut(rut) {
    return true;
  }

  getLabelForPersonalIdentification() {
    return "DNI";
  }

  getLabelForCompanyIdentification() {
    return "CUIT";
  }

  getLabelForRegion() {
    return "Provincia";
  }

  getLabelForCommune() {
    return "Municipio";
  }
}