import React, {useState} from 'react';
import { Row, Col } from 'react-bootstrap';
import columns from './Columns';
import AssignedTaskDataTable from "./AssignedTaskDataTable";

const AssignedTaskIndex = () => {
  const [moreData, setMoreData] = useState(false);

  return (
    <Row>
      <Col md={11} className="mx-auto" xs={12}>
        <Row className="mt-4">
          <Col>
            <h2 className="text-uppercase">Tareas Asignadas</h2>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <AssignedTaskDataTable
              columns={columns}
              moreData={moreData}
              setMoreData={setMoreData}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AssignedTaskIndex;