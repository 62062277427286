import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Spinner } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { SimpleCenteredModal } from '../../components';
import { sendAlert } from '../../actions/utils';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { showProjectRequest, sendEmailRequest } from '../../requests/projects';
import ProjectReceptionForm from './Form/ProjectReceptionForm';
import { updateProjectChecklistRequest } from "../../requests/checklists";

const initialProject = {
  id: '',
  calendarEventId: '',
  checklistsAttributes: [],
  clientId: '',
  mileage: '',
  notes: '',
  projectDate: '',
  projectProgress: '',
  projectReviewsAttributes: [
    {
      documentKeys: [],
      notApply: false,
      notes: '',
      projectWorksAttributes: [],
      projectAutopartsAttributes: [],
      someProjectWork: ''
    }
  ],
  promiseDate: '',
  vehicleId: ''
};

const ProjectReceptionMechanic = ({ match }) => {
  const [modalBody, setModalBody] = useState('');
  const [onRequest, setOnRequest] = useState(false);
  const { id: projectId } = match.params;
  const reviewType = match.url.split('/')[3];
  const [project, setProject] = useState(initialProject);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleShowModal = () => {
    setOnRequest(true);
    setModalBody(
      <>
        <h3 className="text-center">Guardando progreso de OT</h3>
        <p className="text-center lead mb-5">
          Espere un momento mientras se notifica al cliente del progreso de su vehículo
        </p>
        <div className="d-flex justify-content-around w-25 mx-auto mb-3">
          <Spinner animation="grow" variant="success" />
          <Spinner animation="grow" variant="success" />
          <Spinner animation="grow" variant="success" />
        </div>
      </>
    );
  };

  const handleModalClose = () => {
    setModalBody('');
    setOnRequest(false);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    handleModalClose();
  };

  const handleSuccessShow = response => setProject(camelCaseEmptyStringRecursive(response.data));

  const fetchProject = () => {
    showProjectRequest(projectId, {
      dispatch,
      successCallback: handleSuccessShow,
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchProject, [projectId]);

  const handleSuccessSendProgressEmail = (res, redirect) => {
    if (res.data?.message) {
      dispatch(
        sendAlert({
          kind: 'success',
          message: res.data?.message
        })
      );
    }
    handleModalClose();
    history.push(redirect);
  };

  const handleSendProgressEmail = (type, redirect) => {
    sendEmailRequest({
      id: projectId,
      type,
      dispatch,
      successCallback: res => handleSuccessSendProgressEmail(res, redirect),
      failureCallback: handleFailureRequest
    });
    handleShowModal();
  };

  const handleSuccessUpdate = response => {
    const { projectProgress, progressEmailSent } = camelCaseRecursive(response.data);
    let updateType;
    let message;
    let redirect;
    switch (reviewType) {
      case 'pre_inspection':
        updateType = { created: 'actualizada', pre_inspected: 'confirmada' };
        message = `Pre inspección ${updateType[projectProgress]}`;
        redirect = '/calendar';
        break;
      case 'reception':
        updateType = { created: 'actualizada', received: 'confirmada' };
        message = `Recepción ${updateType[projectProgress]}`;
        redirect = '/calendar';
        break;
      case 'mechanic':
        updateType = { received: 'actualizado', reviewed: 'confirmado' };
        message = `Checklist ${updateType[projectProgress]}`;
        redirect = `/projects/${projectId}`;
        break;
      default:
        break;
    }
    dispatch(sendAlert({ kind: 'success', message: `${message} con éxito` }));
    if (projectProgress === 'pre_inspected') {
      if (progressEmailSent !== 'pre_inspection') {
        handleSendProgressEmail('pre_inspection_progress', redirect);
      } else {
        history.push(redirect);
      }
    }
    if (projectProgress === 'received') {
      if (progressEmailSent !== 'reception') {
        handleSendProgressEmail('reception_progress', redirect);
      } else {
        handleModalClose();
      }
    }
    if (projectProgress === 'reviewed') {
      if (progressEmailSent !== 'mechanic') {
        handleSendProgressEmail('mechanic_progress', redirect);
      } else {
        handleModalClose();
      }
    }
  };

  const handleUpdateRequest = async values => {
    const { projectReviewsAttributes, id, checklistsAttributes } = values.project;
    const checklist = project.checklistsAttributes.find(c => c.checklistType === reviewType);
    const params = {}
    params.checklist = { ...checklist };
    params.checklist.promiseDate = values.project.promiseDate;
    params.checklist.mileage = values.project.mileage;
    params.checklist.notes = values.project.notes;
    params.checklist.projectReviewsAttributes = projectReviewsAttributes;
    params.checklist.projectId = id;
    if(params.checklist.checklistType === 'reception') {
      params.checklist.signature = checklistsAttributes[0].signature;
      params.checklist.signatory = checklistsAttributes[0].signatory;
    }
    await updateProjectChecklistRequest(checklist.id, {
      dispatch,
      params: snakeCaseKeys(params),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <Row>
      <Col md={{ span: 10, offset: 1 }}>
        <ProjectReceptionForm
          fromReviewType={reviewType}
          project={project}
          action="edit"
          submitVariant="success"
          formRequest={handleUpdateRequest}
        />
      </Col>
      <SimpleCenteredModal title="Emitir nueva Factura" body={modalBody} show={onRequest} onHide={handleModalClose} />
    </Row>
  );
};

export default ProjectReceptionMechanic;
