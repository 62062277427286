import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Table } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import { Download, Upload } from 'react-feather';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import * as Yup from 'yup';

import '../../services/yupCustomMethods';

import { sendAlert } from '../../actions/utils';
import {
  DefaultModal,
  FormikInput,
  InputRemoteSelect,
  ModalConfirmationButton,
  SimpleCenteredModal
} from '../../components';
import ProviderTab from '../../components/Provider/ProviderTab';
import { createProductRequest, scanProductRequest } from '../../requests/products';
import { debounceIndexProjectsRequest, indexProjectsRequest, showProjectRequest } from '../../requests/projects';
import { camelCaseEmptyStringRecursive, formatToPrice } from '../../services/utils';
import MovementDetailAdd from '../MovementDetail/MovementDetailAdd';
import ProductForm from '../Product/ProductForm';
import ManualMovementForm from './ManualMovementForm';

const basicProduct = {
  categoryId: '',
  code: '',
  cost: '',
  description: '',
  id: '',
  inventoryable: true,
  providerCode: '',
  salePrice: '',
  sku: '',
  unit: '',
  productCategoryId: '',
  providerId: '',
  vehicleBrandId: '',
  vehicleModelId: ''
};

const ProductMovementForm = ({ errors, formRequest, projectCode, setFieldValue, touched, values }) => {
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [movementType, setMovementType] = useState('');
  const [projectDetails, setProjectDetails] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const stableDispatch = useCallback(dispatch, []);

  const {
    productMovement,
    productMovement: {
      invoiceNumber,
      movementDetailsAttributes,
      totalAmount: productMovementTotalAmount,
      providerId,
      projectId
    }
  } = values;

  const movementDetailsTotalAmount = parseFloat(
      movementDetailsAttributes.reduce(
          (sum, movementDetail) => sum + (Number.isFinite(movementDetail.totalAmount) ? movementDetail.totalAmount : 0),
          0
      ).toFixed(2)
  );

  const modelKey = 'productMovement[movementDetailsAttributes]';

  const addMovementDetail = newAttributes => {
    const newValues = [...movementDetailsAttributes, newAttributes];
    setFieldValue(modelKey, newValues);
  };

  const handleSuccessScan = ({ data: vProduct }) => {
    const repeatedProductMovementDetail = movementDetailsAttributes.find(
      movementDetail => movementDetail.productSku === vProduct.sku
    );
    const {
      categoryName: productCategoryName,
      code: productName,
      cost,
      sku: productSku,
      id: productId,
      salePrice: totalAmount
    } = camelCaseEmptyStringRecursive(vProduct);

    if (repeatedProductMovementDetail) {
      if (repeatedProductMovementDetail.productId !== productId) {
        repeatedProductMovementDetail.productId = productId;
        repeatedProductMovementDetail.productName = productName;
        repeatedProductMovementDetail.productCategoryName = productCategoryName;
        repeatedProductMovementDetail.salePrice = cost;
      } else {
        repeatedProductMovementDetail.quantity += 1;
      }
      repeatedProductMovementDetail.totalAmount =
        repeatedProductMovementDetail.quantity * repeatedProductMovementDetail.cost;
      setFieldValue(modelKey, movementDetailsAttributes);
    } else {
      addMovementDetail({
        productCategoryName,
        productName,
        productSku,
        productId,
        quantity: 1,
        salePrice: cost,
        totalAmount
      });
    }
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const scanProduct = sku => {
    scanProductRequest({
      dispatch,
      params: {
        sku
      },
      successCallback: handleSuccessScan,
      failureCallback: handleFailureRequest
    });
  };

  const onKeyDownHandler = keyEvent => {
    if (keyEvent.keyCode === 13) {
      keyEvent.preventDefault();
      const scanInput = document.getElementById('scanProductInput');
      const sku = scanInput.value;
      scanInput.value = '';
      if (sku) {
        scanProduct(sku);
      }
    }
  };

  const handleModalClose = () => {
    setDefaultModalShow(false);
    setModalShow(false);
    setModalTitle('');
    setModalBody('');
    setMovementType('');
  };

  const handleSuccessProductCreate = response => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Ítem creado con éxito' }));
    handleSuccessScan(response);
  };

  const handleCreateProductRequest = params => {
    createProductRequest({
      dispatch,
      params: snakeCaseKeys(params),
      formData: true,
      successCallback: handleSuccessProductCreate,
      failureCallback: handleFailureRequest
    });
  };

  const createProductModal = ({ productSku, salePrice }) => {
    basicProduct.sku = productSku;
    basicProduct.salePrice = salePrice;
    setModalTitle('CREAR ÍTEM');
    setModalShow(true);
    setModalBody(<ProductForm action="new" product={basicProduct} formRequest={handleCreateProductRequest} />);
  };

  const manualAddRequest = ({ product: vProduct }) => {
    handleModalClose();
    scanProduct(vProduct.sku);
  };

  const handleModalConfirmationConfirm = () => {
    history.push(`/products`);
    handleModalClose();
  };

  const manualAddProduct = () => {
    setModalBody(
      <>
        <Row className="justify-content-end">
          <Col className="mb-3" md={3}>
            <ModalConfirmationButton
              handleModalConfirm={handleModalConfirmationConfirm}
              modalTitle="Confirmar ir a ítems"
              modalBody="Al realizar esta acción puede perder cambios no guardados. Confirmar para continuar"
              buttonText="Ver Ítems"
              variant="secondary"
              className="w-100"
            />
          </Col>
        </Row>
        <ManualMovementForm
          formRequest={manualAddRequest}
          handleModalClose={handleModalClose}
          submitVariant="success"
          product={basicProduct}
        />
      </>
    );
    setModalShow(true);
    setModalTitle('Agregar ítem manual');
  };

  const handleConfirmModal = () => {
    const newValues = { ...values, productMovement: { ...productMovement, movementType } };
    formRequest(newValues);
    handleModalClose();
  };

  const openConfirmModal = ({ label: movementTypeLabel, value: movementTypeValue }) => {
    const isIncomeMovement = movementTypeValue === 'income';
    setMovementType(movementTypeValue);
    const invalidMovement = productMovementTotalAmount !== movementDetailsTotalAmount;
    const invalidIncomeMovement = isIncomeMovement && (!invoiceNumber || !providerId);
    const isMovementInvalid = invalidMovement || (isIncomeMovement && invalidIncomeMovement);

    setIsConfirmDisabled(isMovementInvalid);

    setModalBody(
      <>
        {!isMovementInvalid && <p>{`¿Estás seguro que deseas realizar este movimiento de ${movementTypeLabel}?`}</p>}
        <div className="container text-danger">
          {!invoiceNumber && isIncomeMovement && <p>-Este ingreso no tiene una factura asociada</p>}
          {productMovementTotalAmount !== movementDetailsTotalAmount && (
            <p>
              -La suma de los ítems de este movimiento (<strong>Monto total</strong>) no cuadra
            </p>
          )}
          {!providerId && isIncomeMovement && <p>-Este movimiento no tiene un proveedor seleccionado</p>}
        </div>
      </>
    );
    setDefaultModalShow(true);
    setModalTitle('CONFIRMAR MOVIMIENTO');
  };

  const fetchInitialProjects = useCallback(
    params => {
      indexProjectsRequest({
        dispatch: stableDispatch,
        params: {
          ...params,
          for_selector: true,
          sort_column: 'created_at',
          sort_direction: 'desc',
          display_length: 100
        },
        successCallback: response => {
          setProjects(camelCaseRecursive(response.data.data));
          setSelectedProject(projectCode ? response.data.data.find(element => `${element.code}` === projectCode) : '');
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stableDispatch]
  );

  const fetchProjects = (inputValue, callback) => {
    debounceIndexProjectsRequest({
      dispatch,
      params: {
        query: inputValue,
        for_selector: true,
        sort_column: 'created_at',
        sort_direction: 'desc',
        display_length: 20
      },
      successCallback: response => callback(response.data.data)
    });
  };

  const handleSuccessShow = response => {
    const { id, projectDetailsAttributes } = camelCaseEmptyStringRecursive(response.data);
    const data = projectDetailsAttributes.map(projectDetail => ({ ...projectDetail, projectId: id }));
    setProjectDetails(data);
  };

  const fetchProject = () => {
    if (selectedProject) {
      showProjectRequest(selectedProject.code, {
        dispatch,
        params: { movement: true },
        successCallback: handleSuccessShow,
        failureCallback: handleFailureRequest
      });
    }
  };

  useEffect(fetchInitialProjects, [fetchInitialProjects]);
  useEffect(fetchProject, [projectId]);

  return (
    <>
      <Form>
        <Row>
          <Col className="d-flex m-0 p-0" md={3}>
            <Col md={6}>
              <Field name="productMovement[invoiceNumber]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label="Factura / Boleta"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name="productMovement[totalAmount]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    inputType="number"
                    label="Monto Factura"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Col>
          <Col md={2}>
            <ProviderTab abbr errors={errors} touched={touched} values={values} setFieldValue={setFieldValue} />
          </Col>
          <Col md={2}>
            <Field name="productMovement[projectId]">
              {({ field }) => (
                <InputRemoteSelect
                  {...field}
                  isClearable
                  label="OT Referencia"
                  placeholder="Seleccionar Orden de Trabajo"
                  defaultOptions={projects}
                  value={selectedProject}
                  onChange={data => {
                    setSelectedProject(data);
                    setFieldValue(field.name, data ? data.value : '');
                  }}
                  request={fetchProjects}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={4} className="align-self-center ml-auto">
            <Row>
              <Col md={6}>
                <Button
                  block
                  variant="btn btn-output"
                  onClick={() => openConfirmModal({ label: 'Descuento', value: 'outcome' })}
                >
                  Crear descuento <Download width="15px" height="15px" />
                </Button>
              </Col>
              <Col md={6}>
                <Button
                  block
                  variant="btn btn-success"
                  onClick={() => openConfirmModal({ label: 'Ingreso', value: 'income' })}
                >
                  Crear ingreso <Upload width="15px" height="15px" />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormikInput
              autoComplete="off"
              id="scanProductInput"
              onFocus={e => {
                e.target.placeholder = 'Escaneando...';
              }}
              onBlur={e => {
                e.target.placeholder = 'Escanear';
              }}
              onKeyDown={onKeyDownHandler}
              placeholder="Escanear"
            />
          </Col>
          <Col md={2}>
            <Button variant="btn btn-light-darker" block onClick={manualAddProduct}>
              Agregar ítem manual
            </Button>
          </Col>
        </Row>
        <Row className="mx-auto mt-4">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th style={{ width: '200px' }}>Categoría</th>
                <th style={{ width: '200px' }}>Nombre</th>
                <th style={{ width: '120px' }}>SKU</th>
                <th style={{ width: '200px' }}>Ítem libre</th>
                <th style={{ width: '120px' }}>Stock a ingresar</th>
                <th style={{ width: '120px' }}>Valor unitario</th>
                <th style={{ width: '80px' }}>Total</th>
                <th style={{ width: '10px' }}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <MovementDetailAdd
                createProductModal={createProductModal}
                setFieldValue={setFieldValue}
                movementDetailsAttributes={movementDetailsAttributes}
                modelKey={modelKey}
                touched={touched}
                errors={errors}
                values={values}
                selectedProject={selectedProject}
                projectDetails={projectDetails}
                setProjectDetails={setProjectDetails}
              />
              {movementDetailsAttributes.length > 0 && (
                <tr style={{ backgroundColor: '#e0e0e0' }}>
                  <td colSpan="5" style={{ visibility: 'hidden' }} />
                  <th>Total</th>
                  <td>{formatToPrice(movementDetailsTotalAmount)}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>
      </Form>
      <SimpleCenteredModal body={modalBody} title={modalTitle} show={modalShow} onHide={handleModalClose} closeButton />
      {defaultModalShow && (
        <DefaultModal
          title={modalTitle}
          body={modalBody}
          show={defaultModalShow}
          handleClose={handleModalClose}
          handleConfirm={handleConfirmModal}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
          disableConfirm={isConfirmDisabled}
        />
      )}
    </>
  );
};

const setInitialValues = ({ productMovement }) => ({ productMovement });

const validationSchema = Yup.object().shape({
  productMovement: Yup.object().shape({
    invoiceNumber: Yup.string().nullable(),
    movementType: Yup.string().nullable(),
    providerId: Yup.string().nullable(),
    totalAmount: Yup.number().nullable(),
    movementDetailsAttributes: Yup.array().of(
      Yup.object().shape({
        productId: Yup.string().required('Debes seleccionar un ítem'),
        quantity: Yup.number().required('Debes ingresar una cantidad'),
        salePrice: Yup.number().required('Debes ingresar un valor unitario')
      })
    )
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest, onHide } = props;
  formRequest(values);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false,
  validateOnChange: false
})(ProductMovementForm);
