import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { sendAlert } from '../../../actions/utils';
import { publicShowProjectRequest, publicShowPrivateProjectRequest } from '../../../requests/projects';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import basicProject from './basicProject';
import { useCompany } from '../../../stores/company';
import ProjectPublicContent from './ProjectPublicContent';

const ProjectShowPublic = ({ match }) => {
  const { token, version } = match.params;
  const [project, setProject] = useState(basicProject);
  const [privateProject, setPrivateProject] = useState({});
  const [receptionImages, setReceptionImages] = useState([]);
  const [mechanicImages, setMechanicImages] = useState(basicProject);
  const [preInspectionImages, setPreInspectionImages] = useState(basicProject);
  const [currentVersion, setCurrentVersion] = useState('');
  const [onRequest, setOnRequest] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const {
    user: { id: userId }
  } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const { setWhatsappPhoneNumber, setWebsite, setEmail, setPhoneNumber } = useCompany();
  const [showMechanicChecklistAtStart, setShowMechanicChecklistAtStart] = useState(
    !!(history.location && history.location.search === '?show_mechanic_checklist')
  );
  const [showReceptionChecklistAtStart, setShowReceptionChecklistAtStart] = useState(
    !!(history.location && history.location.search === '?show_reception_checklist')
  );

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    history.push('/');
  };

  const setCompanyLogo = srcImg => {
    document.getElementById('company-main-logo').src = srcImg;
  };

  const handleSuccessRequest = response => {
    const {
      createdAt,
      companyLogo,
      publicProject,
      projectId,
      versionNumber,
      receptionImages: receptionImgs,
      mechanicImages: mechanigImgs,
      website,
      whatsappPhoneNumber,
      email,
      phone,
      preInspectionImages: preInspectionImgs,
      hideSubcategory,
      currentOtState
    } = camelCaseEmptyStringRecursive(response.data);
    setCurrentVersion({ versionNumber, createdAt, projectId });
    setProject({...publicProject, hideSubcategory, currentOtState});
    setReceptionImages(receptionImgs);
    setMechanicImages(mechanigImgs);
    setPreInspectionImages(preInspectionImgs);
    setOnRequest(false);
    if (companyLogo) setCompanyLogo(companyLogo);
    if (website) setWebsite(website);
    if (email) setEmail(email);
    if (phone) setPhoneNumber(phone);
    if (whatsappPhoneNumber) setWhatsappPhoneNumber(whatsappPhoneNumber);
  };

  const fetchPublicProject = () => {
    setOnRequest(true);
    publicShowProjectRequest(token, version, {
      dispatch,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessPrivateProjectShowRequest = response => {
    setPrivateProject(camelCaseEmptyStringRecursive(response.data));
    setOnRequest(false);
  };

  const fetchProject = () => {
    setOnRequest(true);
    publicShowPrivateProjectRequest(token, {
      dispatch,
      successCallback: handleSuccessPrivateProjectShowRequest
    });
  };

  useEffect(fetchPublicProject, [match, moreData]);

  useEffect(fetchProject, []);

  useEffect(() => {
    return () => {
      localStorage.removeItem('whatsappPhoneNumber');
    };
  }, []);

  return onRequest ? (
    <div className="containerSpinnerLoad position-absolute h-100">
      <Spinner animation="border" variant="primary" />
    </div>
  ) : (
    <ProjectPublicContent
      project={project}
      userId={userId}
      currentVersion={currentVersion}
      version={version}
      setMoreData={setMoreData}
      setShowMechanicChecklistAtStart={setShowMechanicChecklistAtStart}
      setShowReceptionChecklistAtStart={setShowReceptionChecklistAtStart}
      showMechanicChecklistAtStart={showMechanicChecklistAtStart}
      showReceptionChecklistAtStart={showReceptionChecklistAtStart}
      receptionImages={receptionImages}
      mechanicImages={mechanicImages}
      preInspectionImages={preInspectionImages}
      privateProject={privateProject}
      token={token}
    />
  );
};

export default ProjectShowPublic;
